export default [
  {
    path: '/affiliate/:hash',
    name: 'auth-login-affiliate',
    component: () => import('@/views/Affiliate/Pages/AffiliatePage.vue'),
    meta: {
      layout: 'blank',
      title: 'Affiliate',
    },
  },
]
