<template>
  <div>
    <v-overlay
      v-show="loading"
      color="white"
      :value="true"
    >
      <v-progress-circular
        indeterminate
        :size="90"
        :width="10"
        color="#3F852F"
      />
    </v-overlay>
    <component
      :is="resolveLayoutVariant"
      v-show="!loading"
      :class="`skin-variant--${appSkinVariant}`"
    >
      <transition
        :name="appRouteTransition"
        mode="out-in"
        appear
      >
        <router-view />
      </transition>

      <!-- Dialog Welcome Instructions -->
      <dialog-welcome-instructions
        v-if="isLoggedIn()"
        v-model="dialogInstructionVisible"
        @close="setDialogState({dialogName:'welcomeInstructionsVisible', value: false })"
      />
      <!-- Dialog Welcome Instructions -->

      <!-- Dialog Tutorials -->
      <dialog-tutorials
        v-if="isLoggedIn()"
        v-model="dialogTutorialsVisible"
        @close="setDialogState({dialogName:'tutorialsVisible', value: false })"
      />
      <!-- Dialog Tutorials -->
    </component>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api'
import useAppConfig from '@core/@app-config/useAppConfig'
import { useRouter } from '@core/utils'
import { useLayout } from '@core/layouts/composable/useLayout'
import { mapGetters, mapMutations } from 'vuex'

// Dynamic vh test
import useDynamicVh from '@core/utils/useDynamicVh'
import moment from 'moment'
import axios from 'axios'
import { isLoggedIn, removeAccessToken } from '@/helpers/authHelper'

// Layouts
import LayoutContentVerticalNav from '@/layouts/variants/content/vertical-nav/LayoutContentVerticalNav.vue'
import LayoutContentHorizontalNav from '@/layouts/variants/content/horizontal-nav/LayoutContentHorizontalNav.vue'
import LayoutBlank from '@/layouts/variants/blank/LayoutBlank.vue'

// Dialogs --
import DialogWelcomeInstructions from '@/components/common/Dialogs/DialogWelcomeInstructions.vue'
import DialogTutorials from '@/components/common/Dialogs/DialogTutorials.vue'

export default {
  components: {
    DialogTutorials,
    LayoutContentVerticalNav,
    LayoutContentHorizontalNav,
    LayoutBlank,
    DialogWelcomeInstructions,
  },

  data() {
    return {
      visible: true,
      fromSsoAuthorization: false,
      loading: false,
    }
  },

  computed: {
    ...mapGetters('ui', ['getDialogVisible']),

    dialogInstructionVisible() {
      return this.getDialogVisible('welcomeInstructionsVisible')
    },

    dialogTutorialsVisible() {
      return this.getDialogVisible('tutorialsVisible')
    },
  },

  watch: {
    $route(to) {
      if (isLoggedIn()) {
        localStorage.setItem('lastPage', to.path)
      }
    },
  },

  created() {
    if (process.env.VUE_APP_SSO === 'true') {
      if (!window.location.pathname.includes('/procurements/confirm')) {
        this.loading = true
      }
    }
  },

  mounted() {
    // SSO Login
    if (process.env.VUE_APP_SSO === 'true') {
      if (!window.location.pathname.includes('/procurements/confirm')) {
      // this.loading = true

        setTimeout(() => {
          axios.post('auth/me')
            .then(() => {
              const flag = localStorage.getItem('refreshTime')

              if (flag) {
                const timeLater = moment(flag).add(10, 'seconds')

                if (timeLater.isBefore(moment())) {
                  localStorage.setItem('refreshTime', moment())

                  if (localStorage.getItem('redirectTo') === '1') {
                    removeAccessToken()
                    window.location.href = `${process.env.VUE_APP_API_BASE_URL}/sso/service-provider/do/sso2?redirect_url=${window.location.href}`
                  }
                }
                this.loading = false
                localStorage.setItem('redirectTo', '1')
              } else { localStorage.setItem('refreshTime', moment()); removeAccessToken(); window.location.href = `${process.env.VUE_APP_API_BASE_URL}/sso/service-provider/do/sso2?redirect_url=${window.location.href}` }
              this.loading = false
            })
            .catch(() => {
              localStorage.setItem('refreshTime', moment())

              window.location.href = `${process.env.VUE_APP_API_BASE_URL}/sso/service-provider/do/login`
            })
        }, 1000)

        setInterval(() => {
          axios.post('auth/refresh').then(response => {
            const accessToken = response.headers.authorization
            localStorage.setItem('access_token', accessToken)
          })
            .catch(() => {
              window.location.href = `${process.env.VUE_APP_API_BASE_URL}/sso/service-provider/do/login`
            })
        }, 1800000)
      }
    }
  },

  methods: {
    ...mapMutations('ui', ['setDialogState']),
    isLoggedIn,
  },

  setup() {
    const { route } = useRouter()
    const { appContentLayoutNav, appSkinVariant, appRouteTransition } = useAppConfig()

    const { handleBreakpointLayoutSwitch } = useLayout()
    handleBreakpointLayoutSwitch()

    const resolveLayoutVariant = computed(() => {
      if (route.value.meta.layout === 'blank') return 'layout-blank'
      if (route.value.meta.layout === 'content' || route.value.meta.layout === 'marketplace') return `layout-content-${appContentLayoutNav.value}-nav`

      return null
    })

    useDynamicVh()

    return {
      resolveLayoutVariant,
      appSkinVariant,
      appRouteTransition,
    }
  },
}
</script>
