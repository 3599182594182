import qs from 'qs'

function parseLanguageFromStartLocation(startLocation) {
  if (!startLocation) return undefined

  const parsedString = qs.parse(startLocation)
  const numberOfKeys = Object.keys(parsedString).length

  if (numberOfKeys === 1 && Object.keys(parsedString)[0].includes('lang')) {
    return Object.values(parsedString)[0]
  }

  return parsedString.lang ? parsedString.lang : undefined
}

export {
  parseLanguageFromStartLocation,
}
