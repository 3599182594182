import axios from 'axios'
import _find from 'lodash/find'
import _sum from 'lodash/sum'
import Vue from 'vue'
import OffersStatusEnum from '@/constants/OffersStatusEnum'
import PurchaseStatusEnum from '@/constants/PurchaseStatusEnum'
import Purchase from '@/Models/Purchase'
import purchaseFilters from '@/views/Procurements/Requests/Data/purchaseFilters'
import purchaseConfirmation from '@/store/purchases/purchase-confirmation'
import duplicatePurchase from '@/views/Procurements/Requests/Data/duplicatePurchase'

const getDefaultState = () => ({
  purchases: {
    data: [],
    current_page: 1,
    total: 0,
    last_page: 0,
  },
  purchase: {},
  purchaseQuantitySum: {},
  purchaseBudget: {},
  duplicatePurchase,
  filters: purchaseFilters,
  loading: false,
})

const state = getDefaultState()

const actions = {
  async fetchPurchases({ commit }, page) {
    try {
      commit('setLoading', true)
      const response = await Purchase.params({ page })
        .where('status', state.filters.status)
        .where('category_id', state.filters.category)
        .where('date_for_offer', state.filters.dateForOffer ? `${state.filters.dateForOffer} 23:59:59` : null)
        .get()
      commit('setLoading', false)
      commit('setPurchases', response)

      return response
    } catch (error) {
      commit('setLoading', false)

      return error
    }
  },

  async fetchPurchaseByStatus({ commit }, { page, status }) {
    try {
      commit('setLoading', true)

      const response = await Purchase.custom(`purchase-steps/initiator/purchases/get/${status}`)
        .params({ page })
        .where('status', state.filters.status)
        .where('category_id', state.filters.category)
        .where('date_for_offer', state.filters.dateForOffer ? `${state.filters.dateForOffer} 23:59:59` : null)
        .get()

      commit('setLoading', false)
      commit('setPurchases', response)

      return response
    } catch (error) {
      commit('setLoading', false)

      return error
    }
  },

  async fetchPurchase({ commit }, purchaseId) {
    try {
      const response = await Purchase.find(purchaseId)
      commit('setPurchase', response)

      return response
    } catch (error) {
      return error
    }
  },

  filterPurchases({ commit, dispatch }, filters) {
    commit('setCurrentPage', 1)
    commit('setFilters', filters)
    dispatch('fetchPurchases', state.purchases.current_page)
  },

  filterPurchasesByStatus({ commit, dispatch }, { filters, status }) {
    commit('setCurrentPage', 1)
    commit('setFilters', filters)
    dispatch('fetchPurchaseByStatus', { filters, status })
  },

  async cancelPurchase({ commit }, purchaseId) {
    try {
      const response = await axios.put(`purchases/cancelPurchase/${purchaseId}`)
      commit('setPurchaseStatus', { purchaseId, status: PurchaseStatusEnum.CANCELED })

      return response
    } catch (error) {
      return error
    }
  },

  async confirmRequest({ commit }, { purchaseId, sectorId }) {
    try {
      const response = await axios.post(`purchase-steps/rfps/send/${purchaseId}`, { sector_id: sectorId })
      commit('setPurchaseStatus', { purchaseId, status: PurchaseStatusEnum.ACTIVE })

      return response
    } catch (error) {
      return error
    }
  },

  async changeRejectStatus({ commit }, {
    purchaseId, note, sectorId, status,
  }) {
    try {
      const response = await axios.put(`purchase-steps/status/${status}/${purchaseId}`, { note, sector_id: sectorId })
      commit('setPurchaseStatus', { purchaseId, status })
      commit('setPurchaseReject', { purchaseId, rejected: 1 })
      commit('setPurchaseNote', { purchaseId, note })

      return response
    } catch (error) {
      return error
    }
  },

  async rejectInitiationRequest({ commit }, { purchaseId, note, sectorId }) {
    const response = await axios.put(`purchase-steps/status/${PurchaseStatusEnum.INITIATED}/${purchaseId}`, { note, sector_id: sectorId })
    commit('setPurchaseStatus', { purchaseId, status: PurchaseStatusEnum.INITIATED })
    commit('setPurchaseReject', { purchaseId, rejected: 1 })

    return response
  },

  async rejectPurchaseRequest({ commit }, { purchaseId, note, sectorId }) {
    const response = await axios.put(`purchase-steps/status/${PurchaseStatusEnum.REQUESTED}/${purchaseId}`, { note, sector_id: sectorId })
    commit('setPurchaseStatus', { purchaseId, status: PurchaseStatusEnum.REQUESTED })
    commit('setPurchaseReject', { purchaseId, rejected: 1 })

    return response
  },

  async changeStatus({ commit }, {
    purchaseId, status, sectorId, value = null, paymentType = null,
  }) {
    try {
      const url = paymentType
        ? `purchase-steps/status/${status}/${purchaseId}?payment_type=${paymentType}`
        : `purchase-steps/status/${status}/${purchaseId}`

      console.log('url', url)

      const response = await axios.put(url, { sector_id: sectorId, value })
      commit('setPurchaseStatus', { purchaseId, status })

      return response
    } catch (error) {
      return error
    }
  },

  async requestPurchase({ commit }, { purchaseId, sectorId }) {
    try {
      const response = await axios.put(`purchase-steps/status/${PurchaseStatusEnum.REQUESTED}/${purchaseId}`, { sector_id: sectorId })
      commit('setPurchaseStatus', { purchaseId, status: PurchaseStatusEnum.REQUESTED })

      return response
    } catch (error) {
      return error
    }
  },

  async confirmPurchase({ commit, rootGetters }, { purchaseId, canConfirm }) {
    try {
      const response = await axios.put(`purchases/offers/confirmOffers/${purchaseId}`, { canConfirm })

      if (rootGetters['user/canConfirmPurchase'] && canConfirm) {
        commit('setPurchaseStatus', { purchaseId, status: PurchaseStatusEnum.CLOSED })
        commit('acceptPurchase', purchaseId)
      } else {
        commit('setPurchaseConfirmedByOwner', { purchaseId, value: 1 })
      }

      return response
    } catch (error) {
      return error
    }
  },

  async rejectOffer({ commit }, offer) {
    try {
      const response = await axios.put(`purchases/offers/rejectOffer/${offer.id}`, { rfp_id: offer.rfp_id })
      commit('setOfferStatus', { purchaseId: offer.purchase_id, rfpId: offer.rfp_id, status: OffersStatusEnum.REJECTED })

      return response
    } catch (error) {
      return error
    }
  },

  async acceptOffer({ commit }, offer) {
    try {
      const response = await axios.put(`purchases/offers/acceptOffer/${offer.id}`, { rfp_id: offer.rfp_id })
      commit('setOfferStatus', { purchaseId: offer.purchase_id, rfpId: offer.rfp_id, status: OffersStatusEnum.ACCEPTED })

      return response
    } catch (error) {
      return error
    }
  },

  async undoOffer({ commit }, offer) {
    try {
      const response = await axios.put(`purchases/offers/undoAcceptedOffer/${offer.id}`, { rfp_id: offer.rfp_id })
      commit('setOfferStatus', { purchaseId: offer.purchase_id, rfpId: offer.rfp_id, status: OffersStatusEnum.OFFER_SENT })

      return response
    } catch (error) {
      return error
    }
  },

  async fetchPurchaseQuantitySum({ commit }, purchaseId) {
    try {
      const response = await axios.get(`purchases/offers/sumQuantity/${purchaseId}`)
      commit('setPurchaseQuantitySum', { purchaseId, value: response.data.value })

      return response
    } catch (error) {
      return error
    }
  },

  async fetchPurchaseBudget({ commit }, { categoryId, purchaseId }) {
    try {
      const response = await axios.get(`budgets/${categoryId}?purchase_id=${purchaseId}`)
      commit('setPurchaseBudget', { purchaseId, data: response.data.data })

      return response
    } catch (error) {
      return error
    }
  },
}

const mutations = {
  resetState(data) {
    Object.assign(data, getDefaultState())
  },

  setPurchases(state, data) {
    state.purchases = data
  },

  setPurchase(state, data) {
    Vue.set(state.purchase, data.id, data)
  },

  setPurchaseQuantitySum(state, { purchaseId, value }) {
    Vue.set(state.purchaseQuantitySum, purchaseId, value)
  },

  setPurchaseBudget(state, { purchaseId, data }) {
    Vue.set(state.purchaseBudget, purchaseId, data)
  },

  setCurrentPage(state, page) {
    state.purchases.current_page = page
  },

  setLoading(state, value) {
    state.loading = value
  },

  setDuplicatePurchase(state, purchase) {
    state.duplicatePurchase = {
      category_id: purchase.category_id,
      description: purchase.description,
      quantity: purchase.quantity,
      unit: purchase.unit,
      articles: purchase.articles !== null && purchase.articles.length ? JSON.parse(purchase.articles) : [],
    }
  },

  setFilters(state, filters) {
    state.filters = filters
  },

  setPurchaseStatus(state, { purchaseId, status }) {
    state.purchase[purchaseId].status = status
  },

  setPurchaseReject(state, { purchaseId, rejected }) {
    state.purchase[purchaseId].rejected = rejected
  },

  setPurchaseNote(state, { purchaseId, note }) {
    state.purchase[purchaseId].note = note
  },

  setOfferStatus(state, { purchaseId, rfpId, status }) {
    const { rfps } = state.purchase[purchaseId]
    const rfp = _find(rfps, ['id', rfpId])
    rfp.status = status
  },

  setPurchaseConfirmedByOwner(state, { purchaseId, value }) {
    state.purchase[purchaseId].confirmed_by_owner = value
  },

  acceptPurchase(state, purchaseId) {
    const { rfps } = state.purchase[purchaseId]
    const acceptedOffers = rfps.filter(rfp => rfp.status === OffersStatusEnum.ACCEPTED)
    const otherOffers = rfps.filter(rfp => rfp.status !== OffersStatusEnum.ACCEPTED && rfp.status !== OffersStatusEnum.PENDING && rfp.status !== OffersStatusEnum.LINK_OPENED)

    // eslint-disable-next-line no-param-reassign
    acceptedOffers.forEach(offer => { offer.status = OffersStatusEnum.CONFIRMED })

    if (otherOffers.length) {
      // eslint-disable-next-line no-param-reassign
      otherOffers.forEach(offer => { offer.status = OffersStatusEnum.REJECTED })
    }
  },
}

const getters = {
  getPurchases: state => state.purchases.data,
  getPurchaseById: state => id => state.purchase[id] || null,
  getTotal: state => state.purchases.total,
  getLastPage: state => state.purchases.last_page,
  getCurrentPage: state => state.purchases.current_page,
  getLoading: state => state.loading,
  getFilters: state => state.filters,
  isPurchaseClosed: state => id => state.purchase[id].status === PurchaseStatusEnum.CLOSED,
  isPurchaseHasQuantity: state => id => {
    if (typeof state.purchase === 'undefined') return false

    return !!state.purchase[id].quantity
  },
  hasOneOrMoreAcceptedOffers: state => id => {
    const { rfps } = state.purchase[id]
    if (typeof rfps === 'undefined') return false

    return rfps.filter(rfp => rfp.offer !== null)
      .some(offer => offer.status === OffersStatusEnum.ACCEPTED)
  },
  getPurchaseQuantitySum: state => id => state.purchaseQuantitySum[id] || null,
  getPurchaseBudget: state => id => state.purchaseBudget[id] || null,
  getSumAcceptedOffers: state => purchaseId => {
    const { rfps } = state.purchase[purchaseId]
    const acceptedRfps = rfps.filter(rfp => rfp.status === OffersStatusEnum.ACCEPTED)
      .map(rfp => rfp.offer.price)

    if (!acceptedRfps.length) return 0

    return _sum(acceptedRfps)
  },
  getDuplicatePurchase: state => state.duplicatePurchase,
}

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
  modules: {
    purchaseConfirmation,
  },
}
