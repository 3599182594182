import axios from 'axios'

const getDefaultState = () => ({
  purchases: {
    data: [],
    current_page: 1,
    total: 0,
    last_page: 0,
  },
  loading: false,
})

const state = getDefaultState()

const actions = {
  async fetchStorekeeperPurchases({ commit }, page) {
    try {
      commit('setLoading', true)
      const response = await axios.get(`purchase-steps/storekeeper/purchases/get?page=${page}`)
      commit('setPurchases', response.data)
      commit('setLoading', false)

      return response
    } catch (error) {
      commit('setLoading', true)

      return error
    }
  },
}

const mutations = {
  resetState(data) {
    Object.assign(data, getDefaultState())
  },

  setPurchases(state, data) {
    state.purchases = data
  },

  setCurrentPage(state, page) {
    state.purchases.current_page = page
  },

  setLoading(state, value) {
    state.loading = value
  },
}

const getters = {
  getPurchases: state => state.purchases.data,
  getTotal: state => state.purchases.total,
  getLastPage: state => state.purchases.last_page,
  getCurrentPage: state => state.purchases.current_page,
  getLoading: state => state.loading,
}

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
}
