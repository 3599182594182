import moment from 'moment/moment'
import axios from 'axios'
import config from '@/config'
import OffersStatusEnum from '@/constants/OffersStatusEnum'

const getDefaultState = () => ({
  loading: true,
  chartsFilters: {
    dates: {
      dateFrom: moment().subtract(1, 'months').format(config.dateFormat),
      dateTo: moment().format(config.dateFormat),
    },
    groupBy: 1,
    category: 0,
    status: OffersStatusEnum.CONFIRMED,
    supplier: 0,
    noValue: 1,
    customer: 0,
  },
  chartsData: {
    count_purchases_by_period: {
      data: [],
      labels: [],
      parent_cats: [],
    },
    total_by_period_and_supplier: {
      data: [],
      labels: [],
      colors: [],
      parent_cats: [],
    },
    total_consumption_by_period: {
      data: [],
      labels: [],
      parent_cats: [],
    },
    count_purchases_by_category: {
      data: [],
      labels: [],
      colors: [],
      parent_cats: [],
    },
    count_purchases_by_customer: {
      data: [],
      labels: [],
      colors: [],
      parent_cats: [],
    },
  },
})

const state = getDefaultState()

const actions = {
  async filterStatistics({ commit, rootGetters }) {
    try {
      commit('setLoading')
      const response = await axios.get(`market-place/statistics/global/get?company_id=0&date_from=${state.chartsFilters.dates.dateFrom}&date_to=${state.chartsFilters.dates.dateTo}&groupby=${state.chartsFilters.groupBy}&category=${state.chartsFilters.category}&status=${state.chartsFilters.status}&supplier=${rootGetters['user/getExternalSupplierId']}&no_value=${state.chartsFilters.noValue}&customer=${state.chartsFilters.customer}`)
      commit('setChartsData', response.data)
      commit('setLoading', false)

      return response
    } catch (error) {
      commit('setLoading', false)

      return error
    }
  },
}

const mutations = {
  resetState(data) {
    Object.assign(data, getDefaultState())
  },

  setChartsData(state, data) {
    state.chartsData = data
  },

  setCategoryFilter(state, categoryId) {
    if (!categoryId) return
    state.chartsFilters.category = categoryId
  },

  setCategoryFilterToDefaultValue(state) {
    state.chartsFilters.category = 0
  },

  setSupplierFilterToDefaultValue(state) {
    state.chartsFilters.supplier = 0
  },

  setSupplierFilter(state, supplierId) {
    if (!supplierId) return
    state.chartsFilters.supplier = supplierId
  },

  setStatusFilter(state, status) {
    if (!status) return
    state.chartsFilters.status = status
  },

  setCustomerFilter(state, customerId) {
    if (!customerId) return

    state.chartsFilters.customer = customerId
  },

  setCustomerFilterToDefaultValue(state) {
    state.chartsFilters.customer = 0
  },

  setLoading(state, loading = true) {
    state.loading = loading
  },
}

const getters = {
  getChartsFilters: state => state.chartsFilters,
  getStatisticByKey: state => key => state.chartsData[key],
  getStatisticLoading: state => state.loading,
}

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
}
