import { i18n } from '@/plugins/i18n'

export default [
  {
    path: '/marketplace/procurements',
    name: 'marketplace.procurements.index',
    component: () => import('@/views/Marketplace/Procurements/Pages/ProcurementsIndexPage.vue'),
    meta: {
      layout: 'marketplace',
      auth: true,
      title: i18n.t('Procurements overview'),
    },
  },
]
