import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { Model } from 'vue-api-query'
import { isLoggedIn, removeAccessToken } from '@/helpers/authHelper'
import router from '@/router'
import { defaultLanguage, languageHeaderName, languageKeys } from '@/config'
import { loadedLanguages } from '@/plugins/i18n'

Vue.use(VueAxios, axios)

Vue.axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL
Vue.axios.defaults.headers.common[languageHeaderName] = localStorage.getItem('lang') && loadedLanguages.includes(localStorage.getItem('lang'))
  ? languageKeys[localStorage.getItem('lang')]
  : languageKeys[defaultLanguage]

axios.interceptors.response.use(
  response => response,
  error => {
    const { response } = error
    const errorCode = response.status

    switch (errorCode) {
      case 401:
        if (isLoggedIn()) {
          router.push({ name: 'auth-login' }).catch(() => Promise.resolve(error))
        }
        break
      case 402:
        router.push({ name: 'payment' }).catch(() => Promise.resolve(error))
        break

      // ToDo
      // case 403:
      //   Vue.prototype.$toast.error('Nemate dozvolu.')
      //   break
      case 409:
        Vue.prototype.$toast.error('Logovanje Vam je zaključano na pet minuta zbog tri pogrešna prijavljivanja za redom.')
        break
      case 429:
        Vue.prototype.$toast.error('To many requests.', { timeout: 0 })
        if (isLoggedIn()) {
          removeAccessToken()
          router.push({ name: 'auth-login' }).catch(() => Promise.resolve(error))
        }
        break
      case 404:
        router.push({ name: '404' }).catch(() => Promise.resolve(error))
        break
      case 500:
        Vue.prototype.$toast.error('Došlo je do greške. Molimo vas kontakrijate našu službu.')
        break
      default:
    }

    return Promise.reject(error)
  },
)

Model.$http = axios
