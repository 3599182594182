import Vue from 'vue'
import VueI18n from 'vue-i18n'
// eslint-disable-next-line camelcase
import sr_latin from '@/plugins/i18n/locales/sr_latin'
import en from '@/plugins/i18n/locales/en'
import ru from '@/plugins/i18n/locales/ru'
import { defaultLanguage } from '@/config'
import store from '@/store'

Vue.use(VueI18n)

const currenLang = localStorage.getItem('lang')
export const loadedLanguages = ['sr_latin', 'en', 'ru'] // our default language that is preloaded

export const i18n = new VueI18n({
  locale: !currenLang || !loadedLanguages.includes(currenLang) ? defaultLanguage : currenLang, // set locale
  fallbackLocale: defaultLanguage,
  messages: {
    sr_latin,
    en,
    ru,
  }, // set locale messages
})

function setI18nLanguage(lang) {
  i18n.locale = lang
  store.commit('ui/setLang', lang)

  return lang
}

export function loadLanguageAsync(lang) {
  if (!loadedLanguages.includes(lang)) {
    // eslint-disable-next-line no-param-reassign
    lang = defaultLanguage
  }

  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language hasn't been loaded yet
  /* eslint-disable prefer-template */
  return import(/* webpackChunkName: "lang-[request]" */ '@/plugins/i18n/locales/' + lang + '.js').then(msgs => {
    i18n.setLocaleMessage(lang, msgs.default)
    loadedLanguages.push(lang)

    return setI18nLanguage(lang)
  })
}
