import { mdiAccountGroupOutline } from '@mdi/js'

export default [
  {
    subheader: 'User management',
    permissions: ['owner-scope', 'admin-scope'],
  },
  {
    title: 'Users',
    icon: mdiAccountGroupOutline,
    to: 'userManagement.users.index',
    permissions: ['owner-scope', 'admin-scope'],
  },
]
