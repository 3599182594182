import srLatin from 'vee-validate/dist/locale/sr_Latin.json'

export default {
  'Welcome back!': 'Dobrodošli nazad!',
  'Try Valuator for free!': 'Isprobajte Valuator potpuno besplatno!',
  appDescription: `
    <p class="mb-2">Tokom prvih 30 dana, Valuator je u potpunosti besplatan, a Vi za to vreme možete da se uverite koliko će Vam pomoći u organizaciji procesa nabavke.</p>
    <p class="mb-2">Jednostavna komunikacija sa dobavljačima, slanje zahteva za ponudom jednim klikom, sortirane i pregledne pristigle ponude, analitika, formiranje izveštaja i lak odabir najbolje ponude. </p>
    <p class="mb-2">Valuator – sve na jednom mestu.</p>
  `,
  loginAppDescription: 'Jednostavna komunikacija sa sopstvenim dobavljačima, slanje zahteva za ponudom, jasan pregled svih pristiglih ponuda, odabir najbolje, analitika, formiranje izveštaja. Sve na jednom mestu.',
  'Forgot Password?': 'Zaboravljena lozinka?',
  'Do not have an account?': 'Nemate nalog?',
  'Create an account': 'Registrujte se',
  'Make an account': 'Napravite nalog',
  'Already have an account?': 'Već imate nalog?',
  'Sign in instead': 'Ulogujte se',
  'I agree to': 'Prihvatam',
  'Remember Me': 'Zapamti me',
  Login: 'Prijavi se',
  Logout: 'Odjavi se',
  Home: 'Početna',
  Documents: 'Dokumenta',
  Add: 'Dodaj',
  Cancel: 'Odustani',
  Delete: 'Obriši',
  Edit: 'Izmeni',
  View: 'Pogledaj',
  Save: 'Sačuvaj',
  Send: 'Pošalji',
  Profile: 'Profil',
  Confirm: 'Potvrdi',
  Description: 'Opis',
  Category: 'Kategorija',
  Company: 'Kompanija',
  Files: 'Dodatni dokumenti',
  PIB: 'PIB',
  Accept: 'Prihvati',
  Reject: 'Odbij',
  'Deadline for response': 'Rok za odgovor',
  'Delivery deadline': 'Rok za isporuku',
  'Select status': 'Izaberite status',
  'Select category': 'Izaberite kategoriju',
  'Delivery date': 'Datum isporuke',
  Back: 'Nazad',
  'No data available': 'Nema dostupnih podataka',
  'Terms of Condition': 'Uslovi korišćenja',
  Close: 'Zatvori',
  Yes: 'Da',
  No: 'Ne',
  Status: 'Status',
  'Created at': 'Datum kreiranje',
  Finances: 'Finansije',
  'Finances overview': 'Pregled finansija',

  purchaseStatuses: {
    Active: 'Aktivni',
    Valuation: 'Valuacija',
    Closed: 'Zatvoreni',
    Canceled: 'Stornirani',
    Initiated: 'Inicijacija',
    'Initiation approved': 'Inicijacija odobrena',
    Requested: 'Predložen zahtev',
    Received: 'Primljena roba / usluga',
    Paid: 'Plaċeno',
    'Ad hoc': 'Ad hoc',
  },
  Active: 'Aktivan',
  Closed: 'Zatvoren',
  'Cancel request': 'Storniraj zahtev',

  SuppliersEmptyText: '',
  Suppliers: 'Dobavljači',
  Supplier: 'Dobavljač',
  'Select supplier': 'Izaberite Dobavljača',
  Categories: 'Kategorije',
  'Categories overview': 'Kategorije',
  'All suppliers': 'Dobavljači',
  Warehouse: 'Magacin',
  Receipts: 'Prijemnice',

  Procurement: 'Nabavka',
  Procurements: 'Nabavke',
  'Procurements overview': 'Procurements overview',
  Request: 'Zahtev',
  Number: 'br.',
  Offers: 'Poslato dobavljačima/Pristigle ponude',
  Offer: 'Ponuda',
  'New request': 'Nov zahtev',
  'Requests overview': 'Pregled zahteva',
  'Closed requests': 'Zatvoreni zahtevi',
  'Confirmed requests': 'Potvrdjeni zahtevi',
  'Privacy policy': 'Uslove korišćenja',
  'Format exm.': 'Format npr: +381652013555',

  successRegistrationMessage: 'Uspešno ste obavili registraciju. Proverite Vaš email poslali smo vam link za potvrdu registracije.',

  'New category': 'Nova kategorija',
  'Add new category': 'Dodaj novu kategoriju',
  'Edit category': 'Izmena kategorije',
  'Confirm deletion': 'Potvrda brisanja',
  confirmDeletionQuestion: 'Ovu akciju ne možete poništiti.',
  'Yes, delete it': 'Da, obriši',
  'No, just kidding': 'Odustani',
  'New supplier': 'Novi dobavljač',
  'Add new supplier': 'Dodaj novog dobavljača',
  'Edit supplier': 'Izmena dobavljača',
  'New contact person': 'Nova kontakt osoba',
  'Save contact person': 'Snimi kontakt osobu',
  'Update contact person': 'Izmeni kontakt osobu',
  Price: 'Cena',
  Received: 'Primljena roba / usluga',
  'Offer Pending': 'Na čekanju',
  'Offer Link opened': 'Otvoren link',
  'Offer Offer sent': 'Ponuda poslata',
  'Offer Accepted': 'Ponuda prihvaćena',
  'Offer Confirmed': 'Ponuda potvrđena',
  'Offer Rejected': 'Ponuda odbijena',
  'Initiation Approved': 'Inicijacija odobrena',
  'Initiation Rejected': 'Inicijacija odbijena',
  'Purchase Requested': 'Predlog zahteva',
  'Purchase Active': 'Aktivan',
  'Purchase Rejected': 'Odbijen',
  'Purchase Valuation': 'Valuacija',
  'Purchase Closed': 'Zatvoren',
  'Purchase Canceled': 'Storniran',
  'Purchase Initiated': 'Iniciran',
  'Purchase Active Home': 'Aktivnih zahteva',
  'Purchase Valuation Home': 'Zahteva u valuaciji',
  'Purchase Closed Home': 'Zatvorenih zahteva',
  'Purchase Canceled Home': 'Storniranih zahteva',
  'Bid value Home': 'Ukupna vrednost svih nabavki',
  'Accepted offers Home': 'Prihvaćenih ponuda',
  'Active suppliers Home': 'Aktivnih dobavljača',
  Note: 'Napomena',
  Quantity: 'Količina',
  Accepted: 'Prihvaćeno',
  of: 'od',
  Undo: 'Opozovi',
  'Confirm request': 'Potvrdi zahtev',
  'Request is closed': 'Zahtev je zatvoren',
  'Request is canceled': 'Zahtev je storniran',
  'Request sent for verification': 'Zahtev je poslat proveru',
  'By month': 'Po mesecu',
  'By week': 'Po sedmici',
  'By category': 'Po kategoriji',
  'Number of purchases by month': 'Broj nabavki za period',
  'Number of purchases by week': 'Broj nabavki za period',
  'Number of purchases by category': 'Broj nabavki po kategoriji',
  'Number of purchases by supplier': 'Broj realizovanih nabavki po dobavljaču',
  'Number of purchases by customer': 'Broj nabavki po kupcu',
  'Consumption by month': 'Vrednost nabavki za period',
  'Consumption by week': 'Vrednost nabavki za period',
  'Minimum and maximum value by category': 'Vrednost nabavki po kategoriji (min-max)',
  'Minimum and maximum value by month': 'Vrednost nabavki za period (min-max)',
  'Total consumption by category': 'Vrednost nabavki po kategoriji (period)',
  'Chart type': 'Tip grafikona',
  'View type': 'Vrsta prikaza',
  'Minimum and maximum value for purchase': 'Minimalna i maksimalna vrednost za nabavku',
  'Procurement number': 'Broj nabavke',
  'Choose number of procurement': 'Izaberi broj nabavke',
  'Add supplier': 'Dodaj dobavljača',
  'View suppliers': 'Vidi dobavljače',
  'There are no suppliers in this category': 'U ovoj kategoriji trenutno nema dobavljača',
  'View categories': 'Vidi kategorije',
  'User management': 'Upravljanje korisnicima',
  Users: 'Korisnici',
  'Create user': 'Kreiraj korisnika',
  'All users': 'All users',
  'Are you confirming the deletion of the user': 'Da li potvrđujete brisanje korisnika',
  'Edit user': 'Izmena korisnika',
  'Can confirm purchase': 'Može da potvrdi nabavku',
  'Select role': 'Izaberi rolu',
  'Are you sure you want to confirm the request': 'Da li ste sigurni da želite da potvrdite zahtev',
  'Are you sure you want to submit a request for approval': 'Da li ste sigurni da želite da pošaljete zahtev proveru',
  'Send for approval': 'Pošalji na proveru',
  'Purchase management': 'Upravljanje nabavkama',
  'Purchase confirmation': 'Potvrda nabavki',
  'Date from': 'Datum od',
  'Date to': 'Datum do',
  'Transportation included': 'Prevoz uključen',
  'Transportation not included': 'Prevoz nije uključen',
  'Maximum amount to confirm': 'Maksimalan iznos za potvrdu',
  Budget: 'Budžet',
  Budgets: 'Budžeti',
  budgetFor: '{categories} od {totalCategories} kategorija',
  'Budgets overview': 'Pregled budžeta',
  'Create budget': 'Kreiraj budžet',
  'Edit budget': 'Izmena budžeta',
  'You have successfully created a budget': 'Uspešno ste kreirali budžet',
  'Select an existing contact person': 'Izaberi postojeću kontakt osobu',
  'Add category': 'Dodaj kategoriju',
  'Can view the budget': 'Može da vidi budžet',
  'Duplicate request': 'Ponovi zahtev',
  Articles: 'Artikli',
  Name: 'Naziv',
  'Order number': 'Redni br.',
  'Unit price': 'Jedinična cena',
  'Quantity requested': 'Tražena količina',
  'Quantity offered': 'Ponudjena količina',
  Projected: 'Projektovano',
  Spent: 'Utrošeno',
  'Number / Value': 'Broj / Vrednost',
  Amount: 'Iznos',
  'Comprehensive data from the platform': 'Sveobuhvatni podaci sa platforme',
  'Forgot your password?': 'Zaboravili ste lozinku?',
  'Enter your email and we will send you instructions for recovering a forgotten password': ' Upišite svoj email i poslaćemo vam instrukcije za povratak zaboravljene lozinke',
  'Return to login page': 'Povratak na stranu za prijavu',
  'Choose avatar': 'Izaberi avatar',
  'Edit Profile': 'Izmeni profil',
  Email: 'Email',
  Phone: 'Telefon',
  'Change avatar': 'Promeni avatar',
  'Change your password': 'Promena lozinke',
  'Password must contain at least 8 characters, one special character, one number, one uppercase and one lowercase letter.': 'Lozinka mora da sadrži najmanje 8 karaktera, jedan specijalni karakter, jedan broj, jedno veliko i jedno malo slovo.',
  'Company information': 'Podaci o kompaniji',
  'Request description': 'Opis zahteva',
  'Describe your request': 'Opišite vaš zahtev',
  'Do you confirm the deletion': 'Da li potvrđujete brisanje',
  'Do you confirm the deletion of the offer': 'Da li potvrđujete brisanje ponude',
  'Are you sure you want to update the offer': 'Da li ste sigurni da želite da ažurirate ponudu',
  'Are you sure you want to submit an offer': 'Da li ste sigurni da želite da pošaljete ponudu',
  'Do you confirm the deletion of the category': 'Da li potvrđujete brisanje kategorije',
  'Do you confirm the deletion of the supplier': 'Da li potvrđujete brisanje dobavljača',
  'Do you confirm the deletion of the user': 'Da li potvrđujete brisanje korisnika',
  'You have successfully submitted your request': 'Uspešno ste poslali zahtev',
  'Wrong PIB code entered': 'Pogrešan PIB kod unet.',
  'Verification is required to place an offer': 'Za davanje ponude potrebna je verifikacija',
  'Please enter your PIB': 'Molimo unesite PIB',
  Instructions: 'Instrukcije',
  'Once the offer has been left, you can update it until the customer confirms the purchase.': 'Jednom ostavljenu ponudu možete ažuritati sve dok kupac ne potvrdi nabavku.',
  'Your offer': 'Vaša ponuda',
  'Total price': 'Ukupna cena',
  'Requested qty.': 'Tražena kol.',
  'Action confirmation': 'Potvrda akcije',
  'You have successfully submitted an offer.': 'Uspešno ste poslali ponudu.',
  'You have successfully modified your offer.': 'Uspešno ste izmenili ponudu.',
  'The supplier has not opened the request.': 'Dobavljač nije otvorio zahtev.',
  'Send again': 'Pošalji ponovo',
  'The supplier has opened a request but has not yet sent a bid.': 'Dobavljač je otvorio zahtev, ali još nije poslao ponudu.',
  'Are you sure you want to accept the offer': 'Da li ste sigurni da želite da prihvatite ponudu',
  'Are you sure you want to decline the offer': 'Da li ste sigurni da želite da odbijete ponudu',
  'Are you sure you want to resend the request to the supplier': 'Da li ste sigurni da želite da ponovo pošaljete zahtev dobavljaču',
  'Are you sure you want to cancel the offer': 'Da li ste sigurni da želite da poništite ponudu',
  'Are you sure you want to cancel the request': 'Da li ste sigurni da želite da stornirate zahtev',
  'Procurement has expired': 'Nabavka je istekla',
  'You can no longer bid on this procurement.': 'Ne možete više ostavljati ponudu za ovu nabavku.',
  'Procurement history for the period (number/value)': 'Istorija nabavki za period (broj/vrednost)',
  footerNav: {
    'All rights Reserved': 'Sva prava zadržana',
    'Terms of use': 'Uslovi koiršćenja',
    'Privacy policy': 'Politika privatnosti',
    'Cookie policy': 'Politika kolačića',
  },
  Currency: 'Valuta',
  'You have successfully added a category. You can now add a supplier.': 'Uspešno ste dodali kategoriju. Sada možete dodati dobavljača.',
  registerSuccess: {
    title: 'Registracija je uspešna!',
    errorTitle: 'Registracija je neuspešna',
    description: 'Uspešno ste obavili registraciju. <strong>Proverite Vaš email</strong>, poslali smo Vam link za potvrdu registracije.',
  },
  createUserSuccessfulMessage: 'Uspešno ste kreirali korisnika',
  newPasswordSentOnMail: 'Nova lozinka Vam je poslata na mail, bićete preusmereni na stranicu za prijavu.',
  anErrorHasOccurred: 'Došlo je do greške',
  sendingInProcess: 'Slanje je u toku...',
  passwordReset: 'Resetovanje lozinke',
  confirmationLinkSentToEmail: 'Link za potvrdu vam je poslat na mail',
  Role: 'Rola',
  Details: 'Detalji',
  General: 'Opšte',
  Security: 'Bezbednost',
  Address: 'Adresa',
  contractFileName: 'nabavka',
  Confirmation: 'Potvrda',

  units: {
    kg: 'kg',
    m: 'm',
    m2: 'm2',
    m3: 'm3',
    t: 't',
    pieces: 'kom',
    l: 'l',
  },

  chartsFilters: {
    title: 'Filteri',
    buttonText: 'Primeni filter',
    offersStatuses: {
      acceptedOffers: 'Prihvaćene ponude',
      declinedOffers: 'Odbijene ponude',
    },
    noValues: {
      numberOffer: 'Broj ponuda',
      valueOffer: 'Vrednost ponuda',
    },
  },
  instructions: {
    title: 'Šta sad da radim?!',
    step01: {
      title: 'Korak: Kreiraj kategorije!',
      description: '(možete kreirati neograničen broj kategorija roba i usluga koje nabavljate)',
    },
    step02: {
      title: 'Korak: Unesi dobavljače za kreirane kategorije!',
      description: '(možete upisati neograničen broj dobavljača za svaku od kategorija)',
    },
    step03: {
      title: 'Korak: Raspiši nabavku!',
      description: '(kreiranjem zahteva za nabavku dobavljači dobijaju sve potrebne informacije da uspešno odgovore na Vaš zahtev)',
    },
    step04: {
      title: 'Korak: Odaberi najbolju ponudu!',
      description: '(pregledajte pristigle ponude dobavljača i odaberite najbolju)',
    },
    step05: {
      title: 'Kraj!',
    },
  },
  passwordSuccessfullyChanged: 'Lozinka uspešno promenjena.',
  imageReplacedSuccessfully: 'Uspešno izmenjena slika.',
  youHaveSuccessfullyChangedTheContactPerson: 'Uspešno ste izmenili kontakt osobu',
  dataChangedSuccessfully: 'Uspešno izmenjeni podaci.',
  confirmationRegistrationSuccessfully: 'Potvrda registracije je uspešna!',
  confirmationRegistrationFailed: 'Potvrda registracije je neuspešna!',
  confirmationRegistrationMessage: 'Na registrovanu mejl adresu ćete dobiti obaveštenje da možete da se prijavite na sistem, nakon što moderator aktivira Vaš nalog.',
  orderPlan: {
    title: 'Naruči paket',
    month: 'mesec',
    months: 'meseca',
    preorderType: 'Tip predračuna',
    selectedPlan: 'Izabrani paket',
    order: 'Naruči',
    serbia: 'Srbija',
    ino: 'Ino',
    confirmationQuestion: 'Da li ste sigurni da želite da poručite paket',
    successfulMessage: 'Uspešno ste poručili paket. Na vašu email adresu poslali smo predračun.',
  },
  genders: {
    all: 'SVE',
    m: 'M',
    f: 'Ž',
  },
  register: {
    snackbarPib: {
      text: 'Da li zelite da popunite podatke o kompaniji?',
      yes: 'Da',
      no: 'Ne',
    },
  },
  registrationNumber: 'Matični broj',
  affiliate: {
    inviteButton: 'Pozovi',
    affiliateInfo: {
      text: 'Vaš affiliate link',
      copy: 'Kopiraj',
      copiedLinkText: 'Affiliate link je kopiran.',
    },
    userInfo: {
      title: 'Vaše informacije',
      name: 'Ime',
      email: 'Email',
      phoneNumber: 'Broj telefona',
      contractNumber: 'Broj ugovora',
      companyName: 'Naziv kompanije',
      pib: 'PIB',
      registrationNumber: 'Matični broj',
    },
    affiliateTable: {
      title: 'Affiliate korisnici',
      trialPeriodExpired: 'Istekao',
      validUntil: 'Važi do',
    },
    inviteModal: {
      title: 'Pozivanje korisnika',
      infoTextLimit: 'Možete poslati do 10 pozivnica odjednom',
      emailsToInvite: 'Email adrese za pozivanje',
      addEmailToInvite: 'Dodajte email adrese za poziv',
      successMessage: 'Uspešno ste pozvali korisnike',
    },
    companyDetailsModal: {
      title: 'Detalji',
      pib: 'PIB',
      registrationNumber: 'Matični broj',
      address: 'Adresa',
    },
    profitDetailsDialog: {
      title: 'Pregled profita',
      transaction: 'Transakcija',
      package: 'Paket',
      amount: 'Iznos',
      share: 'Udeo',
      paidOff: 'Isplaćeno',
      noTransactionsMessage: 'Još uvek nema transakcija',
    },
    commentDialog: {
      title: 'Komentar',
    },
    dialogNote: {
      title: 'Beleška',
    },
  },

  faq: {
    faq01: 'Kako da dodam kategorije i dobavljače?',
    faq02: 'Kako da pokrenem nabavku?',
    faq03: 'Kako da kreiram korisnike?',
  },
  switchToApp: 'Idite na Aplikaciju',
  switchToMarketplace: 'Idite na Marketplace',
  customer: 'Kupac',
  offerDate: 'Datum nabavke',
  addInvoice: 'Dodaj račun',
  addHocPurchase: 'Ad hoc nabavka',
  'Create Ad hoc purchase': 'Kreiraj ad hoc nabavku',
  Storekeeper: 'Magacioner',
  'Ad hoc purchase': 'Ad hoc nabavke',
  'Ad hoc': 'Ad hoc',
  'Ad hoc approved': 'Ad hoc - potvrdjena',
  Paid: 'Plaćeno',
  Invoice: 'Račun',
  'Purchase order': 'Porudžbenica',
  'On hold': 'Na čekanju',
  'Date for document': 'Datum otpremnice',
  'Date for receipt': 'Datum prijema',
  'Additional information': 'Dodatne informacije',
  'Realization suggestion rejected': 'Predlog realizacije odbijen',
  'History of negotiation': 'Istorija pregovaranja',
  Date: 'Datum',
  User: 'Korisnik',
  Time: 'Vreme',
  'Request for supply': 'Zahtev za nabavku',
  'Procurement history': 'Istorija nabavke',
  'Procurement initiated': 'Nabavka inicirana',
  'Initiation approved': 'Inicijacija odobrena',
  'Procurement request proposed': 'Zahtev za nabavku predložen',
  'Procurement announced': 'Nabavka raspisana',
  'Implementation proposed': 'Realizacija predložena',
  'Procurement completed': 'Nabavka realizovana',
  'Goods / services received in the warehouse': 'Roba / usluga primljena u magacin',
  'Goods / services paid': 'Roba / usluga plaćena',
  'Reason for rejection': 'Razlog odbijanja',
  Negotiate: 'Pregovaraj',
  'Attach the delivery note': 'Priloži otpremnicu',
  Initiate: 'Iniciraj',
  Message: 'Propratna poruka',
  'Do you confirm': 'Da li potvrdjujete',
  'Select the purchase value': 'Izaberite vrednost nabavke',
  'Up to four thousand euros': 'Do četiri hiljade evra',
  'More than four thousand euros': 'Više od četiri hiljade evra',
  'Purchase confirmation no.': 'Potvrda nabavke br.',
  'Are you sure': 'Da li ste sigurni',
  'Mark as paid': 'Roba plaćena',
  'Approve purchase': 'Potvrdi nabavku',
  'Advance payment': 'Avansno plaćeno',
  'Choose a payment type': 'Izaberite vrstu plaćanja',
  'Standard payment': 'Standardno plaćanje',
  'Advanced payment': 'Avansno plaćanje',
  'Payment method': 'Način plaćanja',
  Locked: 'Zatvoren - roba isporučena',

  columns: {
    id: 'ID',
    request_number: 'ZAHTEV BR.',
    name: 'NAZIV',
    actions: 'AKCIJE',
    pib: 'PIB',
    status: 'STATUS',
    category: 'KATEGORIJA',
    created_at: 'KREIRAN',
    date_for_offer: 'ROK ZA ODGOVOR',
    date_for_delivery: 'ROK ZA ISPORUKU',
    email: 'EMAIL',
    role: 'ROLA',
    budget: 'BUDŽET',
    spent: 'POTROŠENO',
    date_from: 'DATUM OD',
    date_to: 'DATUM DO',
    join_date: 'DATUM UČLANJENJA',
    activated_date: 'DATUM AKTIVIRANJA',
    trial_period: 'PROBNI PERIOD',
    preorder: 'PREDRAČUN',
    order: 'RAČUN',
    price: 'CENA',
    package: 'PAKET',
    duration: 'TRAJANJE',
    company: 'KOMPANIJA',
    offer_status: 'STATUS PONUDE',
    sector: 'SEKTOR',
  },

  fields: {
    name: 'Naziv',
    email: 'Email',
    password: 'Lozinka',
    oldpassword: 'Stara lozinka',
    newpassword: 'Nova lozinka',
    companyName: 'Naziv kompanije',
    pib: 'PIB',
    nameAndSurname: 'Ime i prezime',
    phone: 'Telefon',
    passwordConfirm: 'Potvrda lozinke',
    privacyPolicy: 'Uslovi korišćenja',
    description: 'Opis',
    contactPerson: 'Kontakt osoba',
    category: 'Kategorija',
    categories: 'Kategorije',
    file: 'Datoteke',
    price: 'Cena',
    total_price: 'Ukupna cena',
    unit_price: 'Jedinična cena',
    date_for_offer: 'Rok za odgovor',
    date_for_delivery: 'Rok za isporuku',
    request_description: 'Opis zahteva',
    offer_description: 'Opis ponude',
    quantity: 'Količina',
    unit: 'Jedinica',
    city: 'Grad',
    companyAddress: 'Adresa kompanije',
    role: 'Rola',
    address: 'Adresa kompanije',
    category_id: 'Kategorija',
    budget: 'Budžet',
    date_from: 'Datum od',
    date_to: 'Datum do',
    amount: 'Iznos',
    note: 'Napomena',
    offer_date: 'Datum nabavke',
    addInvoice: 'Dodaj račun',
    sector: 'Sektor',
    additional_permissions: 'Dodatne permisije',
    message: 'Poruka',
  },
  validation: {
    ...srLatin.messages,
    ...{
      required_checkbox: 'Polje {_field_} je obavezno',
      array_length: 'Polje {_field_} ne sme biti duže od {array_length} karaktera',
      phone_validation: 'Format polja {_field_} nije validan. Validan format +381652013555',
      minmax: 'Polje {_field_} mora da ima između {min} i {max} cifara',
      exist: '{_field_} već postoji',
    },
  },
  filesValidation: 'Dozvoljeni formati fajla su .xls,.xlsx,.doc,.docx,.pdf,.jpg,.jpeg,.png, dozvoljena veličina pojedinačnog fajla je do 5MB.',
}
