import { i18n } from '@/plugins/i18n'

export default [
  {
    path: '/finances',
    name: 'finances.index',
    component: () => import('@/views/Finances/Pages/FinancesIndexPage.vue'),
    meta: {
      layout: 'content',
      auth: true,
      title: i18n.t('Finances'),
      permissions: ['finances'],
    },
  },
  {
    path: '/finances/purchase/:id',
    name: 'finances.purchase.show',
    component: () => import('@/views/Finances/Pages/FinancesPurchaseShowPage.vue'),
    meta: {
      layout: 'content',
      auth: true,
      title: i18n.t('Finances'),
    },
  },
]
