import ru from 'vee-validate/dist/locale/ru.json'

export default {
  'Welcome back!': 'С возвращением!',
  'Try Valuator for free!': 'Попробуйте Valuator бесплатно!',
  appDescription: `
    <p class="mb-2">В течение первых 30 дней использования Valuator полностью бесплатен, и в этот период вы сможете на собственном опыте увидеть, насколько полезен он в организации процесса закупок.</p>
    <p class="mb-2">Легко общайтесь с поставщиками, отправляйте запросы с одним щелчком, получайте ясное представление и легкий доступ ко всем предложениям, аналитику, генерацию отчетов и выбор лучшего предложения.</p>
    <p class="mb-2">Valuator – все в одном месте</p>
  `,
  loginAppDescription: 'Простое общение с вашими собственными поставщиками, отправка запросов на предложения, ясное представление о всех полученных предложениях, выбор лучшего, аналитика, создание отчетов. Все в одном месте.',
  'Forgot Password?': 'Забыли пароль?',
  'Do not have an account?': 'У вас нет аккаунта?',
  'Create an account': 'Создать аккаунт',
  'Make an account': 'Создать аккаунт',
  'Already have an account?': 'Уже есть аккаунт?',
  'Sign in instead': 'Войти вместо этого',
  'I agree to': 'Я согласен с',
  'Remember Me': 'Запомнить меня',
  Login: 'Войти',
  Logout: 'Выйти',
  Home: 'Главная',
  Documents: 'Документы',
  Add: 'Добавить',
  Cancel: 'Отмена',
  Delete: 'Удалить',
  Edit: 'Редактировать',
  View: 'Просмотр',
  Save: 'Сохранить',
  Send: 'Отправить',
  Profile: 'Профиль',
  Confirm: 'Подтвердить',
  Description: 'Описание',
  Category: 'Категория',
  Company: 'Компания',
  Files: 'Добавить файлы',
  PIB: 'ПИБ',
  Accept: 'Принять',
  Reject: 'Отклонить',
  'Deadline for response': 'Срок ответа',
  'Delivery deadline': 'Срок поставки',
  'Select status': 'Выберите статус',
  'Select category': 'Выберите категорию',
  'Delivery date': 'Дата поставки',
  Back: 'Назад',
  'No data available': 'Нет доступных данных',
  'Terms of Condition': 'Условия использования',
  Close: 'Закрыть',
  Yes: 'Да',
  No: 'Нет',
  Status: 'Статус',
  'Created at': 'Создано',
  purchaseStatuses: {
    Active: 'Активно',
    Valuation: 'Оценка',
    Closed: 'Закрыто',
    Canceled: 'Отменено',
  },
  Active: 'Активно',
  Closed: 'Закрыто',
  'Cancel request': 'Отменить запрос',

  Suppliers: 'Поставщики',
  Supplier: 'Поставщик',
  'Select supplier': 'Выберите поставщика',
  Categories: 'Категории',
  'Categories overview': 'Обзор категорий',
  'All suppliers': 'Все поставщики',

  SuppliersEmptyText: '',
  Procurement: 'Закупка',
  Procurements: 'Закупки',
  Request: 'Запрос',
  Offers: 'Предложения',
  Offer: 'Предложение',
  'New request': 'Новый запрос',
  'Requests overview': 'Обзор запросов',
  'Closed requests': 'Закрытые запросы',
  'Confirmed requests': 'Подтвержденные запросы',
  'Privacy policy': 'Политика конфиденциальности',
  'Format exm.': 'Пример формата +381652013555',

  successRegistrationMessage: 'Вы успешно зарегистрировались. Проверьте свою электронную почту, мы отправили вам ссылку для подтверждения регистрации.',

  'New category': 'Новая категория',
  'Add new category': 'Добавить новую категорию',
  'Edit category': 'Редактировать категорию',
  'Confirm deletion': 'Подтвердить удаление',
  confirmDeletionQuestion: 'Вы уверены, что хотите удалить это? Это действие нельзя отменить.',
  'Yes, delete it': 'Да, удалить',
  'No, just kidding': 'Нет, шучу',
  'New supplier': 'Новый поставщик',
  'Add new supplier': 'Добавить нового поставщика',
  'Edit supplier': 'Редактировать поставщика',
  'New contact person': 'Новый контактный лицо',
  'Save contact person': 'Сохранить контактное лицо',
  'Update contact person': 'Обновить контактное лицо',

  Price: 'Цена',
  'Offer Pending': 'В ожидании',
  'Offer Link opened': 'Ссылка открыта',
  'Offer Offer sent': 'Отправлено',
  'Offer Accepted': 'Принято',
  'Offer Confirmed': 'Подтверждено',
  'Offer Rejected': 'Отклонено',
  'Purchase Active': 'Активно',
  'Purchase Valuation': 'Оценка',
  'Purchase Closed': 'Закрыто',
  'Purchase Canceled': 'Отменено',
  'Purchase Active Home': 'Активные запросы',
  'Purchase Valuation Home': 'Оценочные запросы',
  'Purchase Closed Home': 'Закрытые запросы',
  'Purchase Canceled Home': 'Отмененные запросы',
  'Bid value Home': 'Общая стоимость всех запросов',
  'Accepted offers Home': 'Принятые предложения',
  'Rejected offers Home': 'Отклоненные предложения',
  'Active suppliers Home': 'Активные поставщики',
  Note: 'Примечание',
  Quantity: 'Количество',
  Accepted: 'Принято',
  of: 'из',
  Undo: 'Отменить',
  'Confirm request': 'Подтвердить запрос',
  'Request is closed': 'Запрос закрыт',
  'Request is canceled': 'Запрос отменен',
  'Request sent for verification': 'Запрос отправлен на проверку',
  'By month': 'По месяцу',
  'By week': 'По неделе',
  'By category': 'По категории',
  'Number of purchases by month': 'Количество закупок по месяцам',
  'Number of purchases by week': 'Количество закупок по неделям',
  'Number of purchases by category': 'Количество закупок по категориям',
  'Number of purchases by supplier': 'Количество закупок по поставщикам',
  'Consumption by month': 'Расход по месяцам',
  'Consumption by week': 'Расход по неделям',
  'Minimum and maximum value by category': 'Значение по категории (мин-макс)',
  'Minimum and maximum value by month': 'Минимальное и максимальное значение по месяцам',
  'Total consumption by category': 'Общий расход по категории',
  'Chart type': 'Тип диаграммы',
  'View type': 'Тип просмотра',
  'Minimum and maximum value for purchase': 'Минимальное и максимальное значение для закупки',
  'Procurement number': 'Номер закупки',
  'Choose number of procurement': 'Выберите номер закупки',
  'Add supplier': 'Добавить поставщика',
  'View suppliers': 'Просмотреть поставщиков',
  'There are no suppliers in this category': 'В этой категории нет поставщиков',
  'View categories': 'Просмотреть категории',
  'User management': 'Управление пользователями',
  Users: 'Пользователи',
  'Create user': 'Создать пользователя',
  'All users': 'Все пользователи',
  'Are you confirming the deletion of the user': 'Вы подтверждаете удаление пользователя?',
  'Edit user': 'Редактировать пользователя',
  'Can confirm purchase': 'Может подтверждать закупку',
  'Select role': 'Выберите роль',
  'Are you sure you want to confirm the request': 'Вы уверены, что хотите подтвердить запрос?',
  'Are you sure you want to submit a request for approval': 'Вы уверены, что хотите отправить запрос на утверждение?',
  'Send for approval': 'Отправить на утверждение',
  'Purchase management': 'Управление закупками',
  'Purchase confirmation': 'Подтверждение закупки',
  'Date from': 'Дата с',
  'Date to': 'Дата по',
  'Transportation included': 'Транспорт включен',
  'Transportation not included': 'Транспорт не включен',
  'Maximum amount to confirm': 'Максимальная сумма для подтверждения',
  Budget: 'Бюджет',
  Budgets: 'Бюджеты',
  budgetFor: '{categories} из {totalCategories} категорий',
  'Budgets overview': 'Обзор бюджетов',
  'Create budget': 'Создать бюджет',
  'Edit budget': 'Редактировать бюджет',
  'You have successfully created a budget': 'Вы успешно создали бюджет',
  'Select an existing contact person': 'Выберите существующее контактное лицо',
  'Add category': 'Добавить категорию',
  'Can view the budget': 'Может просматривать бюджет',
  'Duplicate request': 'Дублировать запрос',
  Articles: 'Товары',
  Name: 'Название',
  'Order number': 'Номер заказа',
  'Unit price': 'Цена за единицу',
  'Quantity requested': 'Запрошенное количество',
  'Quantity offered': 'Предложенное количество',
  Projected: 'Прогноз',
  Spent: 'Потрачено',
  'Number / Value': 'Номер / Значение',
  Amount: 'Сумма',
  'Comprehensive data from the platform': 'Обширные данные с платформы',
  'Forgot your password?': 'Забыли пароль?',
  'Enter your email and we will send you instructions for recovering a forgotten password': 'Введите свою электронную почту, и мы вышлем вам инструкции по восстановлению забытого пароля',
  'Return to login page': 'Вернуться на страницу входа',
  'Choose avatar': 'Выберите аватар',
  'Edit Profile': 'Редактировать профиль',
  Email: 'Электронная почта',
  Phone: 'Телефон',
  'Change avatar': 'Изменить аватар',
  'Change your password': 'Изменить пароль',
  'Password must contain at least 8 characters, one special character, one number, one uppercase and one lowercase letter.': 'Пароль должен содержать не менее 8 символов, один специальный символ, одну цифру, одну прописную и одну строчную букву.',
  'Company information': 'Информация о компании',
  'Request description': 'Описание запроса',
  'Describe your request': 'Опишите ваш запрос',
  'Do you confirm the deletion': 'Вы подтверждаете удаление?',
  'Do you confirm the deletion of the offer': 'Вы подтверждаете удаление предложения?',
  'Are you sure you want to update the offer': 'Вы уверены, что хотите обновить предложение?',
  'Are you sure you want to submit an offer': 'Вы уверены, что хотите отправить предложение?',
  'Do you confirm the deletion of the category': 'Вы подтверждаете удаление категории?',
  'Do you confirm the deletion of the supplier': 'Вы подтверждаете удаление поставщика?',
  'Do you confirm the deletion of the user': 'Вы подтверждаете удаление пользователя?',
  'You have successfully submitted your request': 'Вы успешно отправили ваш запрос',
  'Wrong PIB code entered': 'Введен неправильный код ПИБ',
  'Verification is required to place an offer': 'Для размещения предложения требуется верификация',
  'Please enter your PIB': 'Введите ваш ПИБ',
  Instructions: 'Инструкции',
  'Once the offer has been left, you can update it until the customer confirms the purchase.': 'После размещения предложения вы можете его обновить до подтверждения покупателем.',
  'Your offer': 'Ваше предложение',
  'Total price': 'Общая стоимость',
  'Requested qty.': 'Запрошенное количество',
  'Action confirmation': 'Подтверждение действия',
  'You have successfully submitted an offer.': 'Вы успешно отправили предложение.',
  'You have successfully modified your offer.': 'Вы успешно изменили предложение.',
  'The supplier has not opened the request.': 'Поставщик не открыл запрос.',
  'Send again': 'Отправить снова',
  'The supplier has opened a request but has not yet sent a bid.': 'Поставщик открыл запрос, но еще не отправил предложение.',
  'Are you sure you want to accept the offer': 'Вы уверены, что хотите принять предложение?',
  'Are you sure you want to decline the offer': 'Вы уверены, что хотите отклонить предложение?',
  'Are you sure you want to resend the request to the supplier': 'Вы уверены, что хотите повторно отправить запрос поставщику?',
  'Are you sure you want to cancel the offer': 'Вы уверены, что хотите отменить предложение?',
  'Are you sure you want to cancel the request': 'Вы уверены, что хотите отменить запрос?',
  'Procurement has expired': 'Срок закупки истек',
  'You can no longer bid on this procurement.': 'Вы больше не можете делать ставки на эту закупку.',
  'Procurement history for the period (number/value)': 'История закупок за период (количество/стоимость)',
  footerNav: {
    'All rights Reserved': 'Все права защищены',
    'Terms of use': 'Условия использования',
    'Privacy policy': 'Политика конфиденциальности',
    'Cookie policy': 'Политика использования файлов cookie',
  },
  Currency: 'Валюта',
  'You have successfully added a category. You can now add a supplier.': 'Вы успешно добавили категорию. Теперь вы можете добавить поставщика.',
  registerSuccess: {
    title: 'Регистрация успешна!',
    errorTitle: 'Ошибка регистрации!',
    description: 'Вы успешно завершили регистрацию. <strong>Проверьте свою электронную почту</strong>, мы отправили вам ссылку для подтверждения регистрации.',
  },
  createUserSuccessfulMessage: 'Вы успешно создали пользователя',
  newPasswordSentOnMail: 'Новый пароль был отправлен на вашу электронную почту, вы будете перенаправлены на страницу входа.',
  anErrorHasOccurred: 'Произошла ошибка.',
  sendingInProcess: 'Отправка в процессе...',
  passwordReset: 'Сброс пароля',
  confirmationLinkSentToEmail: 'Ссылка для подтверждения отправлена на вашу электронную почту',
  Role: 'Роль',
  Details: 'Детали',
  General: 'Общее',
  Security: 'Безопасность',
  Address: 'Адрес',
  contractFileName: 'закупка',
  Confirmation: 'Подтверждение',

  units: {
    kg: 'кг',
    m: 'м',
    m2: 'м²',
    m3: 'м³',
    t: 'т',
    pieces: 'шт.',
    l: 'л',
  },

  chartsFilters: {
    title: 'Фильтры',
    buttonText: 'Применить фильтр',
    offersStatuses: {
      acceptedOffers: 'Принятые предложения',
      declinedOffers: 'Отклоненные предложения',
    },
    noValues: {
      numberOffer: 'Номер предложения',
      valueOffer: 'Стоимость предложения',
    },
  },

  instructions: {
    title: 'Что мне делать сейчас?!',
    step01: {
      title: 'Шаг: Создайте категории!',
      description: '(вы можете создать неограниченное количество категорий для продуктов и услуг, которые вы закупаете)\n',
    },
    step02: {
      title: 'Шаг: Добавьте поставщиков для созданных категорий!',
      description: '(для каждой категории можно добавить неограниченное количество поставщиков)',
    },
    step03: {
      title: 'Шаг: Создайте запрос на закупку!',
      description: '(создавая запрос на закупку, вы предоставляете поставщикам все необходимые детали для ответа на ваш запрос)',
    },
    step04: {
      title: 'Шаг: Выберите лучшее предложение!',
      description: '(просмотрите полученные предложения от поставщиков и выберите лучшее)',
    },
    step05: {
      title: 'Все!',
    },
  },
  passwordSuccessfullyChanged: 'Пароль успешно изменен.',
  imageReplacedSuccessfully: 'Изображение успешно отредактировано.',
  youHaveSuccessfullyChangedTheContactPerson: 'Вы успешно изменили контактное лицо.',
  dataChangedSuccessfully: 'Данные успешно изменены.',
  confirmationRegistrationSuccessfully: 'Успешное подтверждение регистрации!',
  confirmationRegistrationFailed: 'Ошибка подтверждения регистрации!',
  confirmationRegistrationMessage: 'Вы получите уведомление на зарегистрированный адрес электронной почты, что вы можете войти в систему после активации вашей учетной записи модератором.',
  orderPlan: {
    title: 'Пакет заказа',
    month: 'месяц',
    months: 'месяцев',
    preorderType: 'Тип предзаказа',
    selectedPlan: 'Выбранный пакет',
    order: 'Заказать',
    serbia: 'Сербия',
    ino: 'Иностранный',
    confirmationQuestion: 'Вы уверены, что хотите заказать пакет',
    successfulMessage: 'Вы успешно заказали пакет. Мы отправили предзаказ на ваш адрес электронной почты.',
  },
  genders: {
    all: 'Все',
    m: 'М',
    f: 'Ж',
  },
  register: {
    snackbarPib: {
      text: 'Вы хотите заполнить информацию о вашей компании?',
      yes: 'Да',
      no: 'Нет',
    },
  },
  registrationNumber: 'Регистрационный номер',
  affiliate: {
    inviteButton: 'Пригласить',
    affiliateInfo: {
      text: 'Ваша партнерская ссылка',
      copy: 'Копировать',
      copiedLinkText: 'Партнерская ссылка скопирована.',
    },
    userInfo: {
      title: 'Ваша информация',
      name: 'Имя',
      email: 'Электронная почта',
      phoneNumber: 'Номер телефона',
      contractNumber: 'Номер договора',
      companyName: 'Название компании',
      pib: 'ПИБ',
      registrationNumber: 'Регистрационный номер',
    },
    affiliateTable: {
      title: 'Партнерские пользователи',
      trialPeriodExpired: 'Истек',
      validUntil: 'Действительно до',
    },
    inviteModal: {
      title: 'Пригласить пользователей',
      infoTextLimit: 'Вы можете отправить до 10 приглашений одновременно',
      emailsToInvite: 'Email-адреса для приглашения',
      addEmailToInvite: 'Добавить email-адреса для приглашения',
      successMessage: 'Вы успешно пригласили пользователей',
    },
    companyDetailsModal: {
      title: 'Детали',
      pib: 'ПИБ',
      registrationNumber: 'Регистрационный номер',
      address: 'Адрес',
    },
    profitDetailsDialog: {
      title: 'Обзор прибыли',
      transaction: 'Транзакция',
      package: 'Пакет',
      amount: 'Сумма',
      share: 'Доля',
      paidOff: 'Оплачено',
      noTransactionsMessage: 'Пока нет транзакций',
    },
    commentDialog: {
      title: 'Комментарий',
    },
    dialogNote: {
      title: 'Примечание',
    },
  },

  faq: {
    faq01: 'Как добавить категории и поставщиков?',
    faq02: 'Как начать закупку?',
    faq03: 'Как создать пользователей?',
  },

  columns: {
    id: 'ID',
    request_number: 'НОМЕР ЗАПРОСА',
    name: 'ИМЯ',
    actions: 'ДЕЙСТВИЯ',
    pib: 'ПИБ',
    status: 'СТАТУС',
    category: 'КАТЕГОРИЯ',
    created_at: 'СОЗДАНО',
    date_for_offer: 'КОНЕЧНЫЙ СРОК ОТВЕТА',
    date_for_delivery: 'СРОК ДОСТАВКИ',
    email: 'EMAIL',
    role: 'РОЛЬ',
    budget: 'БЮДЖЕТ',
    spent: 'РАСХОДЫ',
    date_from: 'ДАТА С',
    date_to: 'ДАТА ПО',
    join_date: 'ДАТА РЕГИСТРАЦИИ',
    activated_date: 'ДАТА АКТИВАЦИИ',
    trial_period: 'ИСПЫТАТЕЛЬНЫЙ ПЕРИОД',
    preorder: 'ПРЕДЗАКАЗ',
    order: 'ЗАКАЗ',
    price: 'ЦЕНА',
    package: 'ПАКЕТ',
    duration: 'ДЛИТЕЛЬНОСТЬ',
    company: 'КОМПАНИЯ',
    offer_status: 'СТАТУС ПРЕДЛОЖЕНИЯ',
  },

  fields: {
    name: 'Имя',
    email: 'Email',
    password: 'Пароль',
    oldpassword: 'Старый пароль',
    newpassword: 'Новый пароль',
    companyName: 'Название компании',
    pib: 'ПИБ',
    nameAndSurname: 'Имя и фамилия',
    phone: 'Телефон',
    passwordConfirm: 'Подтверждение пароля',
    privacyPolicy: 'Политика конфиденциальности',
    description: 'Описание',
    contactPerson: 'Контактное лицо',
    category: 'Категория',
    categories: 'Категории',
    file: 'Файлы',
    price: 'Цена',
    total_price: 'Общая стоимость',
    unit_price: 'Цена за единицу',
    date_for_offer: 'Срок ответа',
    date_for_delivery: 'Срок доставки',
    request_description: 'Описание запроса',
    offer_description: 'Описание предложения',
    quantity: 'Количество',
    unit: 'Единица',
    city: 'Город',
    companyAddress: 'Адрес компании',
    role: 'Роль',
    category_id: 'Категория',
    budget: 'Бюджет',
    date_from: 'Дата с',
    date_to: 'Дата по',
    amount: 'Сумма',
    note: 'Примечание',
  },
  validation: {
    ...ru.messages,
    ...{
      required_checkbox: 'Поле {_field_} обязательно для заполнения',
      array_length: 'Поле {_field_} не должно превышать {array_length} символов',
      phone_validation: 'Поле {_field_} должно иметь формат +381652013555',
      minmax: 'Поле {_field_} должно содержать от {min} до {max} цифр',
      exist: 'Поле {_field_} уже существует',
    },
  },
  filesValidation: 'Разрешенные форматы файлов: .xls, .xlsx, .doc, .docx, .pdf, .jpg, .jpeg, .png. Максимальный размер одного файла - до 5 МБ.',
}
