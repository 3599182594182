import { mdiStore } from '@mdi/js'

export default [
  {
    subheader: 'Warehouse',
    permissions: ['storekeeper'],
  },
  {
    title: 'Receipts',
    icon: mdiStore,
    to: 'warehouse.storekeeper.index',
    permissions: ['storekeeper'],
  },
]
