<template>
  <v-dialog
    :value="visible"
    max-width="50rem"
    persistent
    scrollable
    @input="onClose"
  >
    <v-card>
      <v-card-text>
        <faq ref="faq" />
      </v-card-text>

      <v-divider />

      <v-card-actions class="justify-end">
        <v-btn
          color="error"
          @click="onClose"
        >
          {{ $t('Close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>

import Faq from '@/views/Profile/Components/Faq.vue'

export default {
  name: 'DialogTutorials',
  components: { Faq },

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },

  methods: {
    onClose() {
      if (this.$refs.faq.$refs.ytVideo.length) {
        this.$refs.faq.$refs.ytVideo.forEach(video => {
          const playerInstance = video.getPlayerInstance()

          if (playerInstance) {
            playerInstance.stopVideo()
          }
        })
      }

      this.$emit('close')
    },
  },
}
</script>
