<template>
  <v-expansion-panels
    v-model="currentItem"
    accordion
    tile
    mandatory
  >
    <v-expansion-panel
      v-for="(item,index) in items"
      :key="index"
    >
      <v-expansion-panel-header class="text-h6">
        {{ index+1 }}. {{ item.title }}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div style="max-width: 700px; margin: 0 auto">
          <LazyYoutubeVideo
            ref="ytVideo"
            inject-player-script
            enablejsapi
            webp
            :src="item.ytEmbedLink"
          />
        </div>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
export default {
  name: 'Faq',

  data() {
    return {
      currentItem: 0,
    }
  },

  computed: {
    items() {
      return [
        {
          title: this.$t('faq.faq01'),
          ytEmbedLink: 'https://www.youtube.com/embed/zbjcvwGMnEo?rel=0',
        },
        {
          title: this.$t('faq.faq02'),
          ytEmbedLink: 'https://www.youtube.com/embed/H2IigrK6UHU?rel=0',
        },
        {
          title: this.$t('faq.faq03'),
          ytEmbedLink: 'https://www.youtube.com/embed/cMVrhNG__9Y?rel=0',
        },
      ]
    },
  },

  watch: {
    currentItem() {
      this.stopVideos()
    },
  },

  methods: {
    stopVideos() {
      if (this.$refs.ytVideo.length) {
        this.$refs.ytVideo.forEach(video => {
          const playerInstance = video.getPlayerInstance()

          if (playerInstance) {
            playerInstance.stopVideo()
          }
        })
      }
    },
  },
}
</script>
