import {
  mdiCash,
} from '@mdi/js'

export default [
  {
    subheader: 'Budgets',
    permissions: ['owner-scope', 'admin-scope'],
  },
  {
    title: 'Budgets overview',
    icon: mdiCash,
    to: 'budgets.index',
    permissions: ['owner-scope', 'admin-scope'],
  },
]
