import Vue from 'vue'
import {
  required, email, confirmed, min, max, numeric,
} from 'vee-validate/dist/rules'
import {
  ValidationProvider, ValidationObserver, extend, configure,
} from 'vee-validate'
import { i18n } from '@/plugins/i18n'

configure({
  mode: 'aggressive',
  defaultMessage: (field, values) => {
    // eslint-disable-next-line no-param-reassign,no-underscore-dangle
    values._field_ = i18n.t(`fields.${field}`)

    // eslint-disable-next-line no-underscore-dangle
    return i18n.t(`validation.${values._rule_}`, values)
  },
})

// built-in validators
extend('required', required)
extend('email', email)
extend('confirmed', confirmed)
extend('min', min)
extend('max', max)
extend('numeric', numeric)

// custom validator
extend('required_checkbox', value => !!value)
extend('phone_validation', value => {
  const regex = new RegExp('^\\+\\d{11,12}$')

  return !!regex.test(value)
})

extend('minmax', {
  validate(value, { min, max }) {
    const { length } = value

    return length >= min && length <= max
  },
  params: ['min', 'max'],
})

extend('exist', {
  validate(value, array) {
    if (!array.length) return false
    const found = array.find(element => element === value)

    return !found
  },
})

Vue.component('ValidationObserver', ValidationObserver)
Vue.component('ValidationProvider', ValidationProvider)
