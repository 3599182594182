const state = {
  shallContentShowOverlay: false,
  footer: {
    siteUrl: {
      en: 'https://valuator.rs/en',
      sr_latin: 'https://valuator.rs',
    },
    links: {
      en: [
        {
          name: 'Terms of service',
          href: 'https://valuator.rs/en/terms-of-service',
        },
        {
          name: 'Privacy policy',
          href: 'https://valuator.rs/en/privacy-policy',
        },
        {
          name: 'Cookie policy',
          href: 'https://valuator.rs/en/cookie-policy',
        },
      ],
      sr_latin: [
        {
          name: 'Uslovi koiršćenja',
          href: 'https://valuator.rs/uslovi-korscenja',
        },
        {
          name: 'Politika privatnosti',
          href: 'https://valuator.rs/politika-privatnosti',
        },
        {
          name: 'Politika kolačića',
          href: 'https://valuator.rs/politika-kolacica',
        },
      ],
    },
  },
}

const getters = {}

const mutations = {
  TOGGLE_CONTENT_OVERLAY(state, value) {
    state.shallContentShowOverlay = value !== undefined ? value : !state.shallContentShowOverlay
  },
}

const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
