import axios from 'axios'
import PackagesEnum from '@/constants/PackagesEnum'

const allowedPricingPlans = [PackagesEnum.BASIC]
const getDefaultState = () => ({
  pricingPlans: [],
})

const state = getDefaultState()

const actions = {
  async fetchPricingPlans({ commit }) {
    const response = await axios.get('user-profile/pricings/get')
    commit('setPricingPlans', response.data)

    return response
  },

  async createPreorder(_, payload) {
    return axios.post('user-profile/preorder', payload)
  },
}

const mutations = {
  resetState(data) {
    Object.assign(data, getDefaultState())
  },

  setPricingPlans(state, payload) {
    state.pricingPlans = payload
  },
}

const getters = {
  getPricingPlans: state => state.pricingPlans.filter(value => allowedPricingPlans.some(key => value.key === key)),
}

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
}
