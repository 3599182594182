import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('user', ['isTrial', 'isBasic', 'isBasicPlus', 'isAdvanced', 'isPremium']),

    packageIsTrial() {
      return !!this.isTrial
    },

    packageIsBasic() {
      return !!this.isBasic
    },

    packageIsBasicPlus() {
      return !!this.isBasicPlus
    },

    packageIsAdvanced() {
      return !!this.isAdvanced
    },

    packageIsPremium() {
      return !!this.isPremium
    },
  },
}
