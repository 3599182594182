import axios from 'axios'

const getDefaultState = () => ({
  user: {
    id: 0,
    company_name: null,
    email: null,
    name: null,
    pib: null,
    registration_number: null,
    phone_number: null,
  },
})

const actions = {
  async sendInvitations(_, { email, hash }) {
    // eslint-disable-next-line no-return-await
    return await axios.post('affiliate', { email, hash })
  },
}

const state = getDefaultState()

const mutations = {
  resetState(data) {
    Object.assign(data, getDefaultState())
  },

  setUser(state, user) {
    state.user = user
  },
}

const getters = {
  getAffiliateUser: state => state.user,
}

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
}
