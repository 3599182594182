import axios from 'axios'
import Category from '@/Models/Category'

const getDefaultState = () => ({
  data: {
    data: [],
    current_page: 1,
    total: 0,
    last_page: 0,
  },
  contactPersons: [],
  loading: false,
})

const state = getDefaultState()

const actions = {
  async fetchCategories({ commit }, page) {
    try {
      commit('setLoading', true)

      const response = await Category.custom('market-place/categories/get')
        .params({ page })
        .get()

      commit('setLoading', false)
      commit('setCategories', response)

      return response
    } catch (error) {
      return error
    }
  },

  async fetchContactPersons({ commit }) {
    const response = await axios.get('market-place/contact-persons/get')

    commit('setContactPersons', response.data)

    return response
  },

  async addCategoryToSupplier(context, { payload, update = false }) {
    const url = update ? 'market-place/category/add?update=1' : 'market-place/category/add?update=0'

    return axios.post(url, payload)
  },

  async updateCategoryToSupplier(context, { payload, update = false }) {
    const url = update ? `market-place/category/add/${payload.contact_person_id}?update=1` : `market-place/category/add/${payload.contact_person_id}?update=0`

    return axios.put(url, payload)
  },
}

const mutations = {
  resetState(data) {
    Object.assign(data, getDefaultState())
  },

  setCategories(state, data) {
    state.data = data
  },

  setCurrentPage(state, page) {
    state.data.current_page = page
  },

  setContactPersons(state, contactPersons) {
    state.contactPersons = contactPersons
  },

  setLoading(state, value) {
    state.loading = value
  },
}

const getters = {
  getCategories: state => state.data.data,
  getTotal: state => state.data.total,
  getLastPage: state => state.data.last_page,
  getCurrentPage: state => state.data.current_page,
  getLoading: state => state.loading,
  getContactPersons: state => state.contactPersons,
}

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
}
