<template>
  <v-menu
    offset-y
    nudge-bottom="22"
    :nudge-right="center ? 75 : null"
    min-width="175"
    left
    :elevation="$vuetify.theme.dark ? 9 : 8"
  >
    <!-- Activator -->
    <template v-slot:activator="{ on, attrs }">
      <div
        v-bind="attrs"
        class="d-flex align-center mx-4"
        v-on="on"
      >
        <v-img
          :src="require(`@/assets/images/flags/${$i18n.locale}.png`)"
          :alt="$i18n.locale"
          height="14px"
          width="22px"
        />
        <!--        <span v-if="$vuetify.breakpoint.smAndUp">{{ locales.find(l => l.locale === $i18n.locale).title }}</span>-->
      </div>
    </template>

    <!-- Options List -->
    <v-list>
      <v-list-item-group
        :value="$i18n.locale"
        @change="updateActiveLocale"
      >
        <v-list-item
          v-for="locale in locales"
          :key="locale.locale"
          :value="locale.locale"
        >
          <v-img
            :src="locale.img"
            height="14px"
            width="22px"
            :alt="locale.locale"
            class="me-2"
          />
          <v-list-item-title>{{ locale.title }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>
import useAppConfig from '@core/@app-config/useAppConfig'
import { onMounted } from '@vue/composition-api'
import { useRouter } from '@core/utils'
import { loadLanguageAsync, loadedLanguages } from '@/plugins/i18n'
import { languageHeaderName, defaultLanguage, languageKeys } from '@/config'
import { parseLanguageFromStartLocation } from '@/helpers/queryLanguageHelper'

export default {
  props: {
    center: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { root }) {
    const { isRtl } = useAppConfig()

    const updateActiveLocale = locale => {
      if (!locale) return

      // Set to RTL mode if locale is arabic
      isRtl.value = locale === 'ar'
      localStorage.setItem('lang', locale)

      // eslint-disable-next-line no-param-reassign
      root.$vuetify.lang.current = locale
      root.$moment.locale(locale)
      // eslint-disable-next-line no-param-reassign
      root.$http.defaults.headers.common[languageHeaderName] = languageKeys[locale]

      loadLanguageAsync(locale)
    }

    onMounted(() => {
      const { route, router } = useRouter()
      // eslint-disable-next-line no-underscore-dangle
      const lang = parseLanguageFromStartLocation(router.history._startLocation) || route.value.query.lang || localStorage.getItem('lang')

      if (!lang || lang === 'undefined' || !loadedLanguages.includes(lang)) {
        localStorage.setItem('lang', defaultLanguage)
        updateActiveLocale(defaultLanguage)
      } else {
        updateActiveLocale(lang)
      }
    })

    const locales = [
      {
        title: 'Srpski',
        img: require('@/assets/images/flags/sr_latin.png'),
        locale: 'sr_latin',
      },
      {
        title: 'English',
        img: require('@/assets/images/flags/en.png'),
        locale: 'en',
      },

      // {
      //   title: 'Ru',
      //   img: require('@/assets/images/flags/en.png'),
      //   locale: 'ru',
      // },
    ]

    return {
      locales,
      updateActiveLocale,
    }
  },
}
</script>
