export default [
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/Auth/Login.vue'),
    meta: {
      layout: 'blank',
      guest: true,
      title: 'Login',
    },
  },
  {
    path: '/login/sso',
    name: 'auth-login-sso',
    component: () => import('@/views/Auth/LoginSso.vue'),
    meta: {
      layout: 'blank',
      guest: true,
      title: 'Login',
    },
  },
  {
    path: '/login/sso-authorization',
    name: 'auth-login-sso-authorization',
    component: () => import('@/views/Auth/LoginSsoAuthorization.vue'),
    meta: {
      layout: 'blank',
      guest: true,
      title: 'Login',
    },
  },
  {
    path: '/register',
    name: 'auth-register',
    component: () => import('@/views/Auth/Register.vue'),
    meta: {
      layout: 'blank',
      guest: true,
      title: 'Register',
    },
  },
  {
    path: '/register-success',
    name: 'auth-register-success',
    component: () => import('@/views/Auth/RegisterSuccess.vue'),
    meta: {
      layout: 'blank',
      guest: true,
      title: 'Registration Successful',
    },
  },
  {
    path: '/register/confirm/:hash',
    name: 'auth-register-confirmation',
    component: () => import('@/views/Auth/RegisterConfirmation.vue'),
    meta: {
      layout: 'blank',
      guest: true,
      title: 'Register Confirmation',
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/Auth/ForgotPassword.vue'),
    meta: {
      layout: 'blank',
      guest: true,
      title: 'Forgot Password',
    },
  },
  {
    path: '/send-password/:hash',
    name: 'auth-send-password',
    component: () => import('@/views/Auth/SendPassword.vue'),
    meta: {
      layout: 'blank',
      guest: true,
      title: 'Send Password',
    },
  },
  {
    path: '/marketplace/register',
    name: 'auth-marketplace-register',
    component: () => import('@/views/Auth/RegisterMarketplace.vue'),
    meta: {
      layout: 'blank',
      guest: true,
      title: 'Register',
    },
  },
]
