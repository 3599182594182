import Vue from 'vue'
import VueRouter from 'vue-router'
import { isLoggedIn } from '@/helpers/authHelper'
import { i18n } from '@/plugins/i18n'
import store from '@/store'
import auth from '@/views/Auth/routes'
import documents from '@/views/Documents/routes'
import categories from '@/views/Categories/routes'
import suppliers from '@/views/Suppliers/routes'
import procurements from '@/views/Procurements/routes'
import userManagement from '@/views/UserManagement/routes'
import budgets from '@/views/Budgets/routes'
import affiliate from '@/views/Affiliate/routes'
import storekeeper from '@/views/Warehouse/Storekeeper/routes'
import marketplaceCategories from '@/views/Marketplace/Categories/routes'
import marketplaceProcurements from '@/views/Marketplace/Procurements/routes'
import finances from '@/views/Finances/routes'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'home',
    meta: {
      guest: true,
    },
  },
  {
    path: '/home',
    name: 'home',
    component: () => import('@/views/Home/Pages/Home.vue'),
    meta: {
      layout: 'content',
      auth: true,
      title: i18n.t('Home'),
    },
  },
  {
    path: '/marketplace/home',
    name: 'marketplace-home',
    component: () => import('@/views/Marketplace/Home/Pages/HomePage.vue'),
    meta: {
      layout: 'marketplace',
      auth: true,
      title: i18n.t('Home'),
    },
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/views/Profile/Pages/Profile.vue'),
    meta: {
      layout: 'content',
      auth: true,
      title: i18n.t('Profile'),
    },
  },
  {
    path: '/payment',
    name: 'payment',
    component: () => import('@/views/PaymentPage.vue'),
    meta: {
      layout: 'blank',
      auth: true,
      title: 'Payment',
    },
  },
  ...auth,
  ...documents,
  ...affiliate,
  ...categories,
  ...suppliers,
  ...procurements,
  ...userManagement,
  ...budgets,
  ...marketplaceCategories,
  ...marketplaceProcurements,
  ...storekeeper,
  ...finances,
  {
    path: '/select-platform',
    name: 'select-platform',
    component: () => import('@/views/SelectPlatformPage.vue'),
    meta: {
      layout: 'blank',
      auth: true,
      title: 'Select platform',
    },
  },
  {
    path: '/404',
    name: '404',
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'blank',
      auth: false,
      title: 'Error 404',
    },
  },
  {
    path: '*',
    component: () => import('@/views/Error404.vue'),
    meta: {
      layout: 'blank',
      auth: false,
      title: 'Error 404',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(route => route.meta.guest)) {
    if (isLoggedIn()) {
      next({ name: 'home' })
    }
  }
  next()
})

router.beforeResolve((to, from, next) => {
  if (typeof to.meta.permissions !== 'undefined') {
    if (!Vue.prototype.$acl.hasSomePermission(to.meta.permissions)) {
      return next({ name: 'home' })
    }
  }

  if (isLoggedIn()) {
    const isMarketplace = to.fullPath.includes('marketplace')
    const isCompanyActive = store.getters['user/isCompanyActive']
    const isExternalSupplier = store.getters['user/isExternalSupplier']

    if (isMarketplace && !isExternalSupplier) {
      return next({ name: 'home' })
    }

    if (!isMarketplace && !isCompanyActive) {
      return next({ name: 'marketplace-home' })
    }
  }

  return next()
})

router.afterEach(to => {
  Vue.nextTick(() => {
    document.title = `${to.meta.title} | ${process.env.VUE_APP_NAME}`
  })
})

export default router
