import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import en from 'vuetify/lib/locale/en'
// eslint-disable-next-line camelcase
import sr_latin from 'vuetify/lib/locale/sr-Latn'
import preset from '@/@core/preset/preset'
import { defaultLanguage } from '@/config'

Vue.use(Vuetify)

export default new Vuetify({
  preset,
  lang: {
    locales: { sr_latin, en },
    defaultLocale: defaultLanguage,
    current: defaultLanguage,
  },
  icons: {
    iconfont: 'mdiSvg',
  },
  theme: {
    options: {
      customProperties: true,
      variations: false,
    },
  },
})
