import appConfigStoreModule from '@core/@app-config/appConfigStoreModule'
import Vue from 'vue'
import Vuex from 'vuex'
import affiliate from '@/store/affiliate'
import app from '@/store/app'
import user from '@/store/user'
import userManagement from '@/store/user-management'
import categories from '@/store/categories'
import suppliers from '@/store/suppliers'
import purchases from '@/store/purchases'
import statistics from '@/store/statistics'
import ui from '@/store/ui'
import budgets from '@/store/budgets'
import profile from '@/store/profile'
import documents from '@/store/documents'
import marketplace from '@/store/marketplace'
import storekeeper from '@/store/storekeeper'
import finances from '@/store/finances'
import adHocPurchases from '@/store/ad-hoc-purchases'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    appConfig: appConfigStoreModule,
    affiliate,
    app,
    user,
    userManagement,
    categories,
    suppliers,
    purchases,
    statistics,
    ui,
    budgets,
    profile,
    documents,
    marketplace,
    storekeeper,
    finances,
    adHocPurchases,
  },

  actions: {
    resetState({ commit }) {
      commit('user/resetState')
      commit('userManagement/resetState')
      commit('categories/resetState')
      commit('suppliers/resetState')
      commit('purchases/resetState')
      commit('purchases/purchaseConfirmation/resetState')
      commit('statistics/resetState')
      commit('ui/resetState')
      commit('budgets/resetState')
      commit('profile/resetState')
      commit('documents/resetState')
      commit('marketplace/auth/resetState')
      commit('storekeeper/resetState')
      commit('finances/resetState')
      commit('adHocPurchases/resetState')
    },
  },
})
