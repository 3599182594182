<template>
  <v-dialog
    :value="visible"
    max-width="50rem"
    persistent
    scrollable
    @input="$emit('close')"
  >
    <v-card>
      <v-card-text>
        <h1 class="text-center">
          {{ $t('instructions.title') }}
        </h1>
      </v-card-text>

      <v-divider />

      <v-card-text>
        <ol style="font-size: 16px;">
          <li class="mb-2">
            <p class="ma-0 font-weight-bold">
              {{ $t('instructions.step01.title') }}
            </p>
            <p class="ma-0">
              {{ $t('instructions.step01.description') }}
            </p>
          </li>
          <li class="mb-2">
            <p class="ma-0 font-weight-bold">
              {{ $t('instructions.step02.title') }}
            </p>
            <p class="ma-0">
              {{ $t('instructions.step02.description') }}
            </p>
          </li>
          <li class="mb-2">
            <p class="ma-0 font-weight-bold">
              {{ $t('instructions.step03.title') }}
            </p>
            <p class="ma-0">
              {{ $t('instructions.step03.description') }}
            </p>
          </li>
          <li class="mb-2">
            <p class="ma-0 font-weight-bold">
              {{ $t('instructions.step04.title') }}
            </p>
            <p class="ma-0">
              {{ $t('instructions.step04.description') }}
            </p>
          </li>
          <li>
            <p class="ma-0 font-weight-bold">
              {{ $t('instructions.step05.title') }}
            </p>
          </li>
        </ol>
      </v-card-text>

      <v-card-text class="d-flex align-center justify-center mb-2">
        <v-img
          :src="appLogo"
          max-height="50px"
          max-width="50px"
          alt="logo"
          contain
          class="me-3"
        />

        <h2 class="text-2xl font-weight-semibold">
          {{ appName }}
        </h2>
      </v-card-text>

      <v-divider />

      <v-card-actions class="d-flex justify-end">
        <v-btn
          color="primary"
          @click="$emit('close')"
        >
          {{ $t('Close') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapMutations } from 'vuex'
import themeConfig from '@themeConfig'

export default {
  name: 'DialogWelcomeInstructions',

  props: {
    value: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
    }
  },

  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },

  mounted() {
    if (!localStorage.getItem('dialogWelcomeInstructions')) {
      this.setDialogState({ dialogName: 'welcomeInstructionsVisible', value: true })

      localStorage.setItem('dialogWelcomeInstructions', 'true')
    }
  },

  methods: {
    ...mapMutations('ui', ['setDialogState']),
  },
}
</script>
