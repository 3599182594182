import Document from '@/Models/Document'

const getDefaultState = () => ({
  data: {
    data: [],
    current_page: 1,
    total: 0,
    last_page: 0,
  },
  loading: false,
})

const state = getDefaultState()

const actions = {
  async fetchDocuments({ commit }, page) {
    try {
      commit('setLoading', true)
      const response = await Document.params({ page }).get()
      commit('setLoading', false)
      commit('setDocuments', response)

      return response
    } catch (error) {
      commit('setLoading', false)

      return error
    }
  },
}

const mutations = {
  resetState(data) {
    Object.assign(data, getDefaultState())
  },

  setDocuments(state, data) {
    state.data = data
  },

  setCurrentPage(state, page) {
    state.data.current_page = page
  },

  setLoading(state, value) {
    state.loading = value
  },
}

const getters = {
  getDocuments: state => state.data.data,
  getTotal: state => state.data.total,
  getLastPage: state => state.data.last_page,
  getCurrentPage: state => state.data.current_page,
  getLoading: state => state.loading,
}

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
}
