import axios from 'axios'

const getDefaultState = () => ({
  categories: [],
})

const state = getDefaultState()

const actions = {
  async fetchParentCategories({ commit }) {
    const response = await axios.get('market-place/categories/get-parent')
    commit('setCategories', response.data)

    return response
  },

  async registerMarketplace(context, data) {
    return axios.post('market-place/register/external-supplier', data)
  },
}

const mutations = {
  resetState(data) {
    Object.assign(data, getDefaultState())
  },

  setCategories(state, data) {
    state.categories = data
  },
}

const getters = {
  getCategories: state => state.categories,
}

export default {
  namespaced: true,
  actions,
  state,
  mutations,
  getters,
}
