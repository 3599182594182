import { i18n } from '@/plugins/i18n'

export default [
  {
    path: '/documents',
    name: 'documents.index',
    component: () => import('@/views/Documents/Pages/DocumentsIndexPage.vue'),
    meta: {
      layout: 'content',
      auth: true,
      title: i18n.t('Documents'),
    },
  },
]
