import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import '@/styles/styles.scss'
import '@/assets/scss/main.scss'

// 3rd party plugins
import { i18n } from '@/plugins/i18n'
import '@/plugins/axios'
import '@/plugins/websanovaAuth'
import '@/plugins/vue-composition-api'
import '@/plugins/veeValidate'
import '@/plugins/moment'
import '@/plugins/vue-toastification'
import '@/plugins/gtm'
import '@/plugins/vue-lazy-youtube-video'

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App),
}).$mount('#app')
