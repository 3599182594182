import general from '@/navigation/vertical/general'
import procurements from '@/navigation/vertical/procurements'
import warehouse from '@/navigation/vertical/warehouse'
import finances from '@/navigation/vertical/finances'
import userManagement from '@/navigation/vertical/user-management'
import budgets from '@/navigation/vertical/budgets'

export default [
  ...general,
  ...procurements,
  ...warehouse,
  ...finances,
  ...userManagement,
  ...budgets,
]
