import { i18n } from '@/plugins/i18n'

export default [
  {
    path: '/marketplace/categories',
    name: 'marketplace.categories.index',
    component: () => import('@/views/Marketplace/Categories/Pages/CategoriesIndexPage.vue'),
    meta: {
      layout: 'marketplace',
      auth: true,
      title: i18n.t('Categories overview'),
    },
  },
]
