import {
  mdiHomeOutline,
  mdiTagMultipleOutline,
  mdiTruckOutline,
} from '@mdi/js'

export default [
  {
    subheader: 'General',
  },
  {
    title: 'Home',
    icon: mdiHomeOutline,
    to: 'home',
  },
  {
    title: 'Categories overview',
    icon: mdiTagMultipleOutline,
    to: 'categories.index',
    permissions: ['owner-scope', 'admin-scope'],
  },
  {
    title: 'All suppliers',
    icon: mdiTruckOutline,
    to: 'suppliers.index',
    permissions: ['owner-scope', 'admin-scope'],
  },
]
