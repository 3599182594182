import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('user', ['isCompanyActive', 'isExternalSupplier']),

    isMarketplace() {
      return this.$route.fullPath.includes('marketplace')
    },

    switchPlatformItemText() {
      return this.isMarketplace ? this.$t('switchToApp') : this.$t('switchToMarketplace')
    },

    switchPlatformItemShow() {
      return this.isCompanyActive && this.isExternalSupplier
    },
  },

  methods: {
    switchToPlatform() {
      window.location.href = this.isMarketplace
        ? `${process.env.VUE_APP_BASE_URL}/home`
        : `${process.env.VUE_APP_BASE_URL}/marketplace/home`
    },
  },
}
