import auth from '@/store/marketplace/auth'
import categories from '@/store/marketplace/categories'
import procurements from '@/store/marketplace/procurements'
import statistics from '@/store/marketplace/statistics'

export default {
  namespaced: true,
  modules: {
    auth,
    categories,
    procurements,
    statistics,
  },
}
