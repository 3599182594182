import Vue from 'vue'

const languageHeaderName = 'Content-Language'
const defaultLanguage = 'sr_latin'
const defaultCurrency = 'RSD'
const packageCurrency = 'EUR'

const exchangeRate = 118

const currencies = [
  {
    text: 'RSD',
    value: 'RSD',
  },
  {
    text: 'EUR',
    value: 'EUR',
  },
  {
    text: 'GBP',
    value: 'GBP',
  },
  {
    text: 'USD',
    value: 'USD',
  },
]

const config = {
  dateFormat: 'YYYY-MM-DD',
  dateFormatPreview: 'DD.MM.YYYY',
  dateTimeFormat: 'DD.MM.YYYY HH:mm:ss',
  firstDayOfWeek: 1,
}

// This is language keys for backend
const languageKeys = {
  sr_latin: 'sr_Latn',
  en: 'en',
}

function weekDayFormat(isoDate) {
  return Vue.prototype.$moment(isoDate).format('dd')
}

function monthFormat(isoDate) {
  return Vue.prototype.$moment(isoDate).format('MMM')
}

function headerDateFormat(isoDate) {
  return Vue.prototype.$moment(isoDate).format('MMMM YYYY')
}

function titleDateFormat(isoDate) {
  return Vue.prototype.$moment(isoDate).format('ddd DD. MMM')
}

export {
  headerDateFormat,
  titleDateFormat,
  weekDayFormat,
  monthFormat,
  defaultLanguage,
  languageHeaderName,
  languageKeys,
  currencies,
  defaultCurrency,
  exchangeRate,
  packageCurrency,
}

export default config
