import { i18n } from '@/plugins/i18n'

export default [
  {
    path: '/warehouse/storekeeper',
    name: 'warehouse.storekeeper.index',
    component: () => import('@/views/Warehouse/Storekeeper/Pages/StorekeeperIndexPage.vue'),
    meta: {
      layout: 'content',
      auth: true,
      title: i18n.t('Storekeeper'),
    },
  },

  {
    path: '/warehouse/storekeeper/purchase/:id',
    name: 'warehouse.storekeeper.purchase.show',
    component: () => import('@/views/Warehouse/Storekeeper/Pages/StorekeeperPurchaseShowPage.vue'),
    meta: {
      layout: 'content',
      auth: true,
      title: i18n.t('Storekeeper'),
    },
  },
]
