import axios from 'axios'
import Vue from 'vue'
import _merge from 'lodash/merge'
import PackagesEnum from '@/constants/PackagesEnum'
import defaultCompanySettings from '@/config/defaultCompanySettings'
import User from '@/Models/User'
import CompaniesStatusEnum from '@/constants/CompaniesStatusEnum'

const getDefaultState = () => ({
  currentUser: {
    id: null,
    name: null,
    email: null,
    email_verified_at: null,
    created_at: null,
    updated_at: null,
    company_id: null,
    phone_number: null,
    profile_picture: '',
    purchase_confirmation: 0,
    confirm_limit: 0,
    file_name: '1.png',
    company: {
      id: null,
      name: null,
      pib: null,
      pricing: {
        key: PackagesEnum.TRIAL,
        name: 'Trial',
      },
      settings: defaultCompanySettings,
      status: 0,
      external_supplier: 0,
      external_supplier_object: {
        status: CompaniesStatusEnum.INIT,
      },
    },
    roles: [],
    sectors: [],
  },
  ownerRoles: [],
  sectors: [],
})

const actions = {
  async fetchCurrentUser({ commit }) {
    const response = await axios.post('auth/me')
    commit('setCurrentUser', new User(response.data))

    return response
  },

  async fetchRolesForOwner({ commit }) {
    try {
      const response = await axios.get('user-management/owner/getRoles')
      commit('setOwnerRoles', response.data)

      return response
    } catch (error) {
      return error
    }
  },

  async fetchUserSectors({ commit }) {
    try {
      const response = await axios.get('user-management/sectors/getAll')
      commit('setUserSectors', response.data)

      return response
    } catch (error) {
      return error
    }
  },
}

const state = getDefaultState()

const mutations = {
  resetState(data) {
    Object.assign(data, getDefaultState())
  },

  setCurrentUser(data, payload) {
    state.currentUser = payload
    const companySettings = state.currentUser.company.settings

    if (!companySettings) {
      state.currentUser.company.settings = defaultCompanySettings

      return
    }

    state.currentUser.company.settings = _merge(defaultCompanySettings, JSON.parse(companySettings))
  },

  setUserSectors(state, data) {
    state.sectors = data
  },

  setOwnerRoles(state, payload) {
    state.ownerRoles = payload
  },

  setUserAvatar: (state, payload) => {
    state.currentUser.file_name = payload.file_name
  },

  setUserName: (state, payload) => {
    state.currentUser.name = payload.name
  },
}

const getters = {
  getCurrentUser: state => state.currentUser,
  getUserCompanyId: state => state.currentUser.company_id,
  getCompanyPib: state => state.currentUser.company.pib,
  getUserCompanyName: state => state.currentUser.company.name,
  getOwnerRoles: state => state.ownerRoles,
  canConfirmPurchase: state => state.currentUser.purchase_confirmation || Vue.prototype.$acl.isOwner(),
  getConfirmLimit: state => (state.currentUser.confirm_limit ? state.currentUser.confirm_limit : 0),
  getCompanySettings: state => state.currentUser.company.settings,
  getCompanyCurrency: state => state.currentUser.company.settings.currency,
  getSectors: state => state.sectors,
  getUserSectors: state => state.currentUser.sectors,

  isTrial: state => (state.currentUser.company.pricing.key === PackagesEnum.TRIAL),
  isBasic: state => (state.currentUser.company.pricing.key === PackagesEnum.BASIC),
  isBasicPlus: state => (state.currentUser.company.pricing.key === PackagesEnum.BASIC_PLUS),
  isAdvanced: state => (state.currentUser.company.pricing.key === PackagesEnum.ADVANCED),
  isPremium: state => (state.currentUser.company.pricing.key === PackagesEnum.PREMIUM),
  isCompanyActive: state => (state.currentUser.company.status === CompaniesStatusEnum.ACTIVE),
  isExternalSupplier: state => {
    if (state.currentUser.company.external_supplier > 0) {
      return state.currentUser.company.external_supplier_object.status === CompaniesStatusEnum.ACTIVE
    }

    return false
  },
  getExternalSupplierId: state => state.currentUser.company.external_supplier,
}

export default {
  namespaced: true,
  actions,
  state,
  getters,
  mutations,
}
