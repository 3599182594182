import { mdiHomeOutline, mdiTagMultipleOutline, mdiNotebookOutline } from '@mdi/js'

export default [
  {
    title: 'Home',
    icon: mdiHomeOutline,
    to: 'marketplace-home',
  },
  {
    title: 'Categories',
    icon: mdiTagMultipleOutline,
    to: 'marketplace.categories.index',
  },
  {
    title: 'Procurements',
    icon: mdiNotebookOutline,
    to: 'marketplace.procurements.index',
  },
]
