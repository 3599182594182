import {
  mdiCash,
} from '@mdi/js'

export default [
  {
    subheader: 'Finances',
    permissions: ['finances'],
  },
  {
    title: 'Finances overview',
    icon: mdiCash,
    to: 'finances.index',
    permissions: ['finances'],
  },
]
