import Vue from 'vue'
import auth from '@websanova/vue-auth/src/v2'
import driverAuthBearer from '@websanova/vue-auth/src/drivers/auth/bearer'
import driverHttpAxios from '@websanova/vue-auth/src/drivers/http/axios.1.x'
import driverRouterVueRouter from '@websanova/vue-auth/src/drivers/router/vue-router.2.x'
import router from '@/router'
import User from '@/Models/User'
import store from '@/store'
import PermissionManager from '@/utils/PermissionManager'

Vue.use(auth, {
  plugins: {
    http: Vue.axios,
    router,
  },
  drivers: {
    auth: driverAuthBearer,
    http: driverHttpAxios,
    router: driverRouterVueRouter,
  },
  options: {
    loginData: {
      url: 'auth/login', method: 'POST', redirect: '/home', fetchUser: true,
    },
    logoutData: {
      url: 'auth/logout', method: 'POST', redirect: '/login', makeRequest: true,
    },
    fetchData: {
      enabled: true, method: 'POST', url: 'auth/me',
    },
    refreshData: {
      enabled: process.env.VUE_APP_SSO !== 'true', interval: 30, method: 'POST', url: 'auth/refresh',
    },
    forbiddenRedirect: null,
    notFoundRedirect: null,
    tokenDefaultKey: 'access_token',
    tokenImpersonateKey: 'access_token_impersonate',

    parseUserData: response => {
      store.commit('user/setCurrentUser', new User(response))
      Vue.prototype.$acl = new PermissionManager(store.getters['user/getCurrentUser'])
    },
  },
})
