<template>
  <div
    class="pulse d-flex justify-center align-center"
    @click="$emit('click')"
  >
    <div
      class="ring"
      :class="identifier"
    />
    <div
      class="ring"
      :class="identifier"
    />
    <div
      class="ring"
      :class="identifier"
    />
    <v-icon
      style="color: white"
      size="20"
    >
      {{ icon }}
    </v-icon>
  </div>
</template>

<script>
import { mdiHelpCircleOutline } from '@mdi/js'
import gsap from 'gsap'

export default {
  name: 'PulseButton',

  props: {
    className: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      default: mdiHelpCircleOutline,
    },
  },

  computed: {
    identifier() {
      return `${this.className}-ring`
    },
  },

  mounted() {
    gsap.to(`.${this.identifier}`, {
      scale: 1.75,
      opacity: 0,
      duration: 2,
      stagger: {
        each: 0.5,
        repeat: -1,
      },
    })
  },
}
</script>

<style scoped lang="scss">
.pulse {
  cursor: pointer;
  background-color: #3F852F;
  height: 30px;
  width: 30px;
  border-radius: 100%;
  position: relative;
}

.ring {
  position: absolute;
  background-color: inherit;
  height: 100%;
  width: 100%;
  border-radius: 100%;
  opacity: 0.8;
}
</style>
